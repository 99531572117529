import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Box,
  Modal,
  Tab,
  Tabs,
  CircularProgress,
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { MdClose } from "react-icons/md";
import { courseService } from "../../service";

const OverallProgress = () => {
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedCourseName, setSelectedCourseName] = useState(null);
  const [leaderBoardData, setLeaderBoardData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [overAllData, setOverAllData] = useState([]);
  const [tabsData, setTabsData] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [loading, setLoading] = useState(true);
  const [leadLoading, setLeadLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    courseService.courseAnalysis().then((res) => {
      if (res.status) {
        setCourseData(res.data);
        setOverAllData(res.overallData);
      }
      setLoading(false);
    });
    //Add select button and pass courseId
     setLeadLoading(true);
    courseService.getEnrollAndUnLockCourse().then((res) => {
      if (res.status) {
        setTabsData(res.data);
        if (res.data?.[0]?.courseId) {
          fetchLeaderBoardData(res.data?.[0]?.courseId);
        }else{
          setLeadLoading(false)
        }
      }
    });
  }, []);

  function fetchLeaderBoardData(courseId) {
    courseService.leaderBoardData(courseId).then((res) => {
      if (res.status) {
        setLeaderBoardData(res.data);
      }
      setLeadLoading(false)
    });
  }

  const handleSelectCourse = (courseId,name) => {
    courseService.mockAnalysis(courseId).then((res) => {
      if (res.status) {
        setSelectedCourse(res.data);
        setSelectedCourseName(name)
       
        setOpen(true);
      }
    });
  };
  // Separate scoreboard data for each course

  const [leaderboardTabsValue, setLeaderboardTabsValue] = useState(0);

  const handleLeaderboardTabsChange = (event, newValue) => {
    fetchLeaderBoardData(tabsData[newValue]?.courseId);
    setLeaderboardTabsValue(newValue);
  };

  return (
    <>
      <Typography variant="h5" sx={{ color: "#666666", pl: 3, pt: 5 }}>
        Analysis
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* Progress Card */}
        <Card style={{ width: "97%", marginBottom: "20px", marginTop: "30px" }}>
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              style={{ marginBottom: "20px" }}
            >
              Overall Progress
            </Typography>
            <Grid container spacing={2}>
              {/* Left side with the table */}
              <Grid item xs={12} sm={6}>
                {/* Course-wise Progress Table */}
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
                    transition: "box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Course
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Completed
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Attended
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Not Attended
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Available Mock
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {/* Display actual course-wise progress data */}
                      {courseData?.length ?courseData?.map((item, index) => (
                        <TableRow
                          key={index}
                          onClick={() => handleSelectCourse(item.courseId,item.courseName)}
                          sx={{
                            cursor: "pointer",
                          }}
                        >
                          <TableCell>{item.courseName}</TableCell>
                          <TableCell>
                            {item.completedMocksPercentage}%
                          </TableCell>

                          <TableCell>{item.attendedCount}</TableCell>
                          <TableCell>{item.unAttendedCount}</TableCell>
                          <TableCell>{item.totalMocks}</TableCell>
                        </TableRow>
                      )):loading ? (
                        <CircularProgress />
                      ) : (
                        <Typography sx={{p:3}}>No Record Found</Typography>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {/* Right side with the PieChart */}
              <Grid item xs={12} sm={6}>
                {/* Individual Mock Progress Pie Chart */}
                <PieChart
                  colors={["green", "yellow", "orange"]}
                  series={[
                    {
                      data: overAllData?.map(({ label, count }) => ({
                        value: count,
                        label,
                      })),
                      highlightScope: { faded: "global", highlighted: "item" },
                      faded: {
                        innerRadius: 30,
                        additionalRadius: -30,
                        color: "gray",
                      },
                    },
                  ]}
                  sx={{
                    marginTop: "20px",
                  }}
                  height={280}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Leader board Card */}
        <Card style={{ width: "97%", marginBottom: "20px", marginTop: "20px" }}>
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              style={{ marginBottom: "20px" }}
            >
              Leaderboard
            </Typography>
            <Tabs
              value={leaderboardTabsValue}
              onChange={handleLeaderboardTabsChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              {tabsData?.map((tab, index) => (
                <Tab
                  key={index}
                  label={tab.courseName}
                  sx={{
                    "& .MuiTab-wrapper": {
                      borderBottom: "2px solid blue", // Change 'blue' to your desired color
                    },
                  }}
                />
              ))}
            </Tabs>
            {tabsData?.length? tabsData?.map((tab, index) => (
              <TabPanel value={leaderboardTabsValue} index={index} key={index}>
                <TableContainer
                  component={Paper}
                  sx={{
                    boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
                    transition: "box-shadow 0.3s ease-in-out",
                    "&:hover": {
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Rank
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Name
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle1" fontWeight="bold">
                            Score
                          </Typography>
                        </TableCell>
                      </TableRow>
                      {leaderBoardData.length?leaderBoardData?.map((item, rankIndex) => (
                        <TableRow key={rankIndex}>
                          <TableCell>{rankIndex + 1}</TableCell>
                          <TableCell>{item.userName}</TableCell>
                          <TableCell>{item.score}</TableCell>
                        </TableRow>
                      )): leadLoading ? (
                        <CircularProgress />
                      ) : (
                        <Typography sx={{p:3}}>No Record Found</Typography>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
            )):leadLoading ? (
              <CircularProgress />
            ) : (
              <Typography sx={{p:3}}>No Record Found</Typography>
            )}
          </CardContent>
        </Card>
        {selectedCourse && (
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 0,
              m: 0,
            }}
          >
            <Box
              sx={{ width: "90%", maxWidth: 1200, background: "#fff", p: 2 }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: 2,
                  mr: 3,
                }}
              >
                <MdClose
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                  size={24}
                />
              </Box>
              {/* Course Title */}
              <Typography variant="h6" align="center" gutterBottom>
                {selectedCourseName} Mock Data
              </Typography>

              {/* Grid container for Table and Pie Chart */}
              <Grid container spacing={2}>
                {/* Table */}
                <Grid item xs={12} md={6}>
                  <TableContainer
                    sx={{
                      mt: 6,
                      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
                      transition: "box-shadow 0.3s ease-in-out",
                      "&:hover": {
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      },
                    }}
                  >
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <Typography variant="subtitle1" fontWeight="bold">
                              Mock Name
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1" fontWeight="bold">
                              Correct
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1" fontWeight="bold">
                              Incorrect
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle1" fontWeight="bold">
                              Not Attended
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {/* Display mock data */}
                        {selectedCourse?.exams?.map((mock, index) => (
                          <TableRow key={index}>
                            <TableCell>{mock.examName}</TableCell>
                            <TableCell>{mock.correct}</TableCell>
                            <TableCell>{mock.incorrect}</TableCell>
                            <TableCell>{mock.notAttended}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>

                {/* Pie Chart */}
                <Grid item xs={12} md={6}>
                  <PieChart
                    colors={["green", "yellow", "orange"]}
                    series={[
                      {
                        data: [
                          { label: "Correct", value: selectedCourse.correct },
                          {
                            label: "Incorrect",
                            value: selectedCourse.incorrect,
                          },
                          {
                            label: "Not Attended",
                            value: selectedCourse.notAttended,
                          },
                        ],
                        highlightScope: {
                          faded: "global",
                          highlighted: "item",
                        },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                      },
                    ]}
                    sx={{ margin: "auto", width: "100%" }}
                    height={300}
                  />
                </Grid>
              </Grid>
            </Box>
          </Modal>
        )}
      </div>
    </>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default OverallProgress;

import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  InputAdornment,
  IconButton,
  Grid,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import { useSnackbar } from "notistack";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { authService } from "../../service/index";
import logoWhiteText from "../../images/StudyMedic_logo.png";

function Login() {
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [password, setPassword] = useState("");
  const [checked, setChecked] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValid, setPasswordValid] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false); // State for modal
  const [otpEmail, setOtpEmail] = useState(""); // State for OTP email
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handlePasswordRecovery = async () => {
    if (!validateEmail(otpEmail)) {
      snackbar("Please enter a valid email address.", { variant: "error" });
      return;
    }

    try {
      // Call your password recovery API here
      // Example: const res = await authService.sendPasswordRecoveryEmail(otpEmail);
      // You may need to handle the response accordingly
      snackbar("Password recovery email sent successfully.", {
        variant: "success",
      });
      handleCloseModal();
    } catch (error) {
      // console.error("Error during password recovery:", error);
      snackbar("Error during password recovery. Please try again.", {
        variant: "error",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    try {
      const res = await authService.userSignIn(email, password, checked);
      setLoading(false);

      if (!res?.status || !res?.data) {
        snackbar(res.message, { variant: "error" });
        return;
      }

      navigate(`/home/${res.data.userId}`);
    } catch (error) {
      // console.error("Error during login:", error);
    }
  };

  const validateForm = () => emailValid && passwordValid;

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setPasswordValid(validatePassword(newPassword));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailValid(validateEmail(newEmail));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleCheckBox = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <>
      <Box>
        {" "}
        <img
          src={logoWhiteText}
          style={{
            width: "160px",
            marginLeft: "80px",
            marginTop: "60px",
            marginBottom: "20px",
          }}
          alt="logo"
        />
      </Box>
      <Grid
        container
        component="main"
        maxWidth="xs"
        sx={{
          display: "flex",
          overflow: "hidden",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sm={3.5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "none",
            zIndex: 2,
            padding: 3,
            position: "relative",
            minHeight: "100%",
            overflow: "hidden",
          }}
        >
          <Typography
            sx={{
              fontSize: "34px",
              color: "#2C2C83",
              ":hover": { color: "#EB568F" },
            }}
          >
            Log In
          </Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={handleEmailChange}
            error={!emailValid}
            helperText={emailValid ? "" : "Invalid email address"}
            onBlur={() => {}}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="password"
            value={password}
            onChange={handlePasswordChange}
            error={!passwordValid}
            helperText={passwordValid ? "" : "Invalid password"}
            onBlur={() => {}}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={(e) => e.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              marginRight: 31,
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckBox}
                  style={{
                    color: "#EB568F",
                  }}
                />
              }
              label={
                <span style={{ color: "#8890A7", fontSize: "14px" }}>
                  Remember me
                </span>
              }
            />
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 2,
              mb: 2,
              borderRadius: "4px",
              background: "#EB568F",
              color: "white",
              height: "43px",
              fontSize: "16px",
              textTransform: "none",
              ":hover": { bgcolor: "#8890A7" },
            }}
            onClick={handleSubmit}
            disabled={!emailValid || !passwordValid}
          >
            LOG IN
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                href="#"
                onClick={handleOpenModal}
                variant="body2"
                sx={{
                  textDecoration: "none",
                  color: "#8890A7",
                  ":hover": { color: "#EB568F" },
                }}
              >
                Forgot password?
              </Link>
              {/* Password Recovery Modal */}
              <Modal
                open={openModal}
                onClose={handleCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{ timeout: 500 }}
              >
                <Fade in={openModal}>
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      bgcolor: "white",
                      boxShadow: 24,
                      p: 3,
                      borderRadius: 4,
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Password Recovery
                    </Typography>
                    <TextField
                      sx={{ mt: 3 }}
                      fullWidth
                      label="Enter your email"
                      variant="outlined"
                      value={otpEmail}
                      onChange={(e) => setOtpEmail(e.target.value)}
                      error={!validateEmail(otpEmail)}
                      helperText={
                        validateEmail(otpEmail) ? "" : "Invalid email address"
                      }
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        mt: 4,
                        background: "#EB568F",
                        ":hover": { bgcolor: "#8890A7" },
                      }}
                      onClick={handlePasswordRecovery}
                    >
                      Send OTP
                    </Button>
                  </Box>
                </Fade>
              </Modal>
            </Grid>
            <Grid item>
              <Link
                href="register"
                variant="body2"
                onClick={() => {
                  navigate("/register");
                }}
                sx={{
                  textDecoration: "none",
                  color: "#8890A7",
                  ":hover": { color: "#EB568F" },
                }}
              >
                {"Don't have an account? Register"}
              </Link>
            </Grid>
          </Grid>
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                mt: 13,
                color: "black",
                ":hover": { color: "#EB568F" },
              }}
            >
              © 2023 StudyMEDIC
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Login;

import { createAsyncThunk, createSlice  } from "@reduxjs/toolkit";

import { authService } from "../../service";
import { getAuthStorage } from "../../utils/storage-utils";

const storage = getAuthStorage();

const initialState = storage
  ? { status: true, message: "", loading: false, data: storage }
  : { status: false, message: "", loading: false };

export const signInAsync = createAsyncThunk(
  "auth/sign-in",
  async ({ email, password, orgId }) => {
    try {
      const res = await authService.userSignIn(email, password);
      return res;
    } catch (err) {
      return { status: false, message: err.message };
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    value: initialState,
  },
  reducers: {
    setAuth: (state, action) => {
      state.value = { ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signInAsync.pending, (state) => {
      state.value = { ...initialState, loading: true };
    });
    builder.addCase(signInAsync.fulfilled, (state, action) => {
      state.value = { ...action.payload, loading: false };
    });
  },
});

export const { setAuth } = authSlice.actions;

export default authSlice.reducer;

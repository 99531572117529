

const dashboardService = (httpClient) => ({
  getDashboardData: async () => {
    try {
      const res = await httpClient.get("/dashboard/get-data");
      return res.data;
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      return {
        status: false,
        message: "Failed to fetch dashboard data, try again!",
      };
    }
  },

  getDisplayTimerToggle: async () => {
    try {
      const res = await httpClient.get("/dashboard/get-display-timer");
      return res.data;
    } catch (error) {
      console.error("Error fetching Display Timer Toggle status:", error);
      return {
        status: false,
        message: "Failed to fetch Display Timer Toggle status, try again!",
      };
    }
  },
});

export default dashboardService;

import { Box } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getAuthStorage } from "../../utils/storage-utils";
import Drawer from "../../components/drawer/drawer";
import Loader from "../Loader/Loader";
import Public from "../public/public";

const Template = ({ children }) => {
  const { id, pid } = useParams();
  const { userID } = useParams();
  // console.log("TEMPLATE_PARAM\t\t\t", id);

  const [loading, setLoading] = useState(true);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [userId, setUserId] = useState(null);
  const [session] = useState(getAuthStorage());

  const navigate = useNavigate();
  useEffect(() => {
    const currentSession = getAuthStorage();
    if (!currentSession || !currentSession.accessToken) {
      setIsLoggedIn(false);
    } else {
      setUserId(currentSession.userId);
      setIsLoggedIn(true);
      // if (location.pathname === "" || location.pathname === "/") {
      //   navigate("/dashboard");
      // }
      // console.log("LOCATION\t\t\t", location);
    }

    setLoading(false);
  }, [session, isLoggedIn]);

  if (loading) {
    return <Loader />;
  }
  return (
    <>
      {isLoggedIn ? (
        <>
          {id || pid ? (
            <Box className="full-height">
              <Drawer>
                <Box className="full-height">
                  {React.Children.map(children, (child) => {
                    return React.cloneElement(child, { userId }); // Pass userId to children
                  })}
                </Box>
              </Drawer>
            </Box>
          ) : (
            <>
              {userID ? (
                <Box className="full-height">
                  <Box className="full-height">
                    {React.Children.map(children, (child) => {
                      return React.cloneElement(child, {
                        userId,
                      }); // Pass userId to children
                    })}
                  </Box>
                </Box>
              ) : (
                <Box className="full-height">
                  <Public>
                    <Box className="full-height">
                      {React.Children.map(children, (child) => {
                        return React.cloneElement(child, {
                          userId,
                        }); // Pass userId to children
                      })}
                    </Box>
                  </Public>
                </Box>
              )}
            </>
          )}
        </>
      ) : (
        <>{navigate("/sign-in")}</>
      )}
    </>
  );
};

export default Template;

const resultService = (httpClient) => {
  //open folder
  const createResult = async (examId) => {
    try {
      const res = await httpClient.post("/result/create-result", { examId });
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch " };
    }
  };
  const upsertAnswer = async (body) => {
    try {
      const res = await httpClient.post("/result/upsert-answer", body);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch " };
    }
  };
  const upsertLongAnswer = async (body) => {
    try {
      const res = await httpClient.post("/result/upsert-long-answer", body);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch " };
    }
  };
  const flagQuestionToggle = async (body) => {
    try {
      const res = await httpClient.post("/result/flag-question-toggle", body);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch " };
    }
  };
  const ExamResult = async (body) => {
    try {
      const res = await httpClient.post("/result/exam-result", body);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch " };
    }
  };
  const longExamResult = async (body) => {
    try {
      const res = await httpClient.post("/result/long-exam-result", body);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch " };
    }
  };
  const getAllAnswers = async (body) => {
    try {
      const res = await httpClient.post("/result/get-all-answers", body);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch " };
    }
  };
  const getAllLongAnswers = async (body) => {
    try {
      const res = await httpClient.post("/result/get-all-long-answers", body);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch " };
    }
  };
  const reportQuestion = async (body) => {
    try {
      const res = await httpClient.post("/result/report-question", body);
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch " };
    }
  };
  return {
    createResult,
    upsertAnswer,
    flagQuestionToggle,
    ExamResult,
    getAllAnswers,
    reportQuestion,
    longExamResult,
    getAllLongAnswers,
    upsertLongAnswer,
  };
};

export default resultService;

import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { CiLock, CiUnlock } from "react-icons/ci";
import logo from "../../images/dashboardlogo.png";
import { useNavigate } from "react-router-dom";
import { courseService, userService } from "../../service";
import { getAuthStorage } from "../../utils/storage-utils";
import PhoneInput from "react-phone-input-2";
const CourseCard = ({ course, onCardClick }) => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [showReplay, setShowReply] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [contactForm, setContactForm] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    message: "",
  });
  const handleCardClick = () => {
    if (lockChecking(course.isLocked, course.isPurchased)) {
      return;
    }
    navigate(`/dashboard/${course?.courseId}`);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
  const handlePhoneNumberChange = (value, data, event, formattedValue) => {
    setContactForm((prevForm) => ({
      ...prevForm,
      phoneNumber: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    contactForm.courseId = course.courseId;
    if (!contactForm.phoneNumber) {
      setErrorMessage("Please enter your phone number.");
      return;
    } else if (contactForm?.phoneNumber?.length < 6) {
      setErrorMessage("Please enter valid phone number.");
      return;
    } else {
      setErrorMessage("");
    }

    userService.createLead(contactForm).then((res) => {
      // if (res.status) {
      setShowReply(true);
      // } else {
      // snackbar("Submission Failed", { variant: "error"  })
      // }
      setContactForm({
        name: "",
        phoneNumber: "",
        email: "",
        message: "",
      });
    });
    // console.log("Contact Form Data:", contactForm);
    handleCloseModal();
  };

  const handleUnlockClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const lockChecking = (isLocked, isPurchased) => {
    if (!isLocked || (isLocked && isPurchased)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Card
      key={course.courseId}
      sx={{
        margin: "10px",
        width: "20%",
        borderRadius: "20px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        transition: "transform 0.3s",
        "&:hover": {
          transform: "scale(1.05)",
        },
        bgcolor: "rgba(211, 211, 211, 0.2)",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        {lockChecking(
          lockChecking(course.isLocked, course.isPurchased),
          course.isPurchased
        ) ? (
          <CiLock
            style={{ fontSize: "40px", color: "#EB568F", marginBottom: "10px" }}
          />
        ) : (
          <CiUnlock
            style={{ fontSize: "40px", color: "#EB568F", marginBottom: "10px" }}
          />
        )}
        <div>
          <Typography variant="h5" sx={{ textAlign: "center",mb:.5 }} component="div">
            {course.courseName}
          </Typography>
          <Typography
            sx={{ textAlign: "center" }}
            variant="body2"
            color="textSecondary"
          >
            {course.description}
          </Typography>
        </div>

        <Button
          onClick={
            lockChecking(course.isLocked, course.isPurchased)
              ? handleUnlockClick
              : handleCardClick
          }
          sx={{
            mt: 2,

            color: lockChecking(course.isLocked, course.isPurchased)
              ? "red"
              : "#EB568F",
            justifySelf: "flex-end",
          }}
        >
          {lockChecking(course.isLocked, course.isPurchased)
            ? "Unlock Course"
            : "View Course"}
        </Button>
      </CardContent>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{"Unlock Course"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This course is locked. Kindly purchase to access it.
          </DialogContentText>
          <form onSubmit={handleSubmit}>
            <TextField
              margin="dense"
              name="name"
              label="Name"
              variant="outlined"
              fullWidth
              required
              value={contactForm.name}
              onChange={handleInputChange}
              sx={{ mb: 1 }}
            />

            <PhoneInput
              country={"in"}
              onChange={handlePhoneNumberChange}
              placeholder="Enter phone number"
              variant="outlined"
              fullWidth
              inputStyle={{
                border: "1px solid darkgrey",
                width: "100%",
                height: "55px",

                // display:"none",
                // backgroundColor: isPhoneNumberValid ? "white" : "#FFEAE8",
                borderRadius: "-5px",
                paddingLeft: "100px",
              }}
              buttonStyle={{
                height: "55px",
                border: "1px solid darkgrey",
                backgroundColor: "white",
                width: "88px",
                paddingLeft: "20px",
              }}
            />
            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="email"
              variant="outlined"
              fullWidth
              required
              value={contactForm.email}
              onChange={handleInputChange}
            />
            <TextField
              margin="dense"
              name="message"
              label="Message"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              value={contactForm.message}
              onChange={handleInputChange}
            />
            <p style={{ color: "red", fontSize: "14px" }}>{errorMessage}</p>
            <DialogActions>
              <Button onClick={handleCloseModal} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <Snackbar
        sx={{ ml: 30 }}
        open={showReplay}
        autoHideDuration={3000} // Adjust the duration as needed
        onClose={() => setShowReply(false)}
        message="Submitted your data successfully" // The message you want to display
      />
    </Card>
  );
};

const Home = ({ handleCourseClick }) => {
  const [loading, setLoading] = useState(true);
  const userData = getAuthStorage();

  const userName =
    userData?.name?.charAt(0)?.toUpperCase() + userData?.name?.slice(1) ||
    "user2";
  const [coursesData, setCourseData] = useState([]);
  useEffect(() => {
    setLoading(true);
    courseService.getAllCourse().then((res) => {
      if (res.status) {
        setCourseData(res.data);
      }
      setLoading(false);
    });
  }, []);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box sx={{ mt: 5 }}>
        <img
          src={logo}
          alt="WelcomeImage"
          style={{
            width: "300px",
            height: "125px",
            margin: "0 auto 50px 50px",
          }}
        />

        <Typography variant="h5" sx={{}}>
          Welcome{" "}
          <span style={{ color: "#EB568F", fontSize: "30px" }}>
            {"   "}
            {userName}
            {"   "}
          </span>{" "}
          to FRCR Platform
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          width: "100%",
          mt: 10,
        }}
        // style={{ display: "flex", flexWrap: "wrap" }}
      >
        {/* Center the course cards */}

        {coursesData?.length ? (
          coursesData?.map((course) => (
            <CourseCard
              key={course.courseId}
              course={course}
              onCardClick={() => handleCourseClick(course.courseId)}
            />
          ))
        ) : loading ? (
          <CircularProgress />
        ) : (
          <Typography>No Record Found</Typography>
        )}
      </Box>
    </Box>
  );
};

export default Home;

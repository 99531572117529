import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  CardContent,
  Modal,
  Card,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { resultService } from "../../service";
import { ImageBaseUrl } from "../../config/api-config";
import ReactPanZoom from "react-image-pan-zoom-rotate";
const Scoreboard = ({ questions, selectedAnswers,examName, resultId, examId }) => {
  const [exitConfirmationOpen, setExitConfirmationOpen] = useState(false);
  const [scoreData, setScoreData] = useState([]);
  const [selectedPaperIndex, setSelectedPaperIndex] = useState(0);
  const [imageIndex] = useState(0);
  // const handleNextImage = () => {
  //   if (imageIndex < scoreData?.[selectedPaperIndex]?.image?.length - 1) {
  //     setImageIndex((prevIndex) => prevIndex + 1);
  //   }
  // };
  const navigate = useNavigate();
  const handleQuestionItemClick = (index) => {
    setSelectedPaperIndex(index);
  };
  const [loading, setLoading] = useState(true);
  // For getting backend data
  useEffect(() => {
    // for delay get last submitted answer
    setLoading(true);
    resultService.ExamResult({ resultId, examId }).then((res) => {
      if (res.status) {
        setScoreData(res.data);
      }
      setLoading(false);
    });
  }, [examId, resultId]);
  return (
    <div>
      <div>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 4,
              mb: 3,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <IconButton onClick={() => setExitConfirmationOpen(true)}>
                <FaArrowLeftLong style={{ color: "#EB568F" }} />
              </IconButton>
              <Typography variant="h4" sx={{ ml: 30, textAlign: "center" }}>
                {examName?.charAt(0)?.toUpperCase() + examName?.slice(1)} Solutions
              </Typography>
            </Box>
          </Box>
          <Modal
            open={exitConfirmationOpen}
            onClose={() => setExitConfirmationOpen(false)}
            aria-labelledby="exit-modal-title"
            aria-describedby="exit-modal-description"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                mr: 50,
              }}
            >
              <Paper
                sx={{
                  width: "600px",
                  padding: "20px",
                  borderRadius: "15px",
                }}
              >
                <Typography variant="h5" id="exit-modal-title" gutterBottom>
                  Are you sure you want to exit the mock?
                </Typography>
                <Typography id="exit-modal-description" sx={{ mb: 2 }}>
                  Your progress will not be saved.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <Button
                    variant="outlined"
                    color="error" // Use the desired color
                    sx={{ borderRadius: "8px", mr: 2 }}
                    onClick={() => setExitConfirmationOpen(false)}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    color="primary" // Use the desired color
                    sx={{ borderRadius: "8px" }}
                    onClick={() => navigate(-1)}
                  >
                    Yes
                  </Button>
                </Box>
              </Paper>
            </Box>
          </Modal>
          {/* Add your scoreboard content here */}
          <div
            style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 30 }}
          >
            <div>
              <Typography variant="h4" sx={{ m: 2, mb: 4 }}>
                Your Score:{" "}
                {
                  scoreData?.filter((data, i) => data.isCorrect !== false)
                    .length
                }
                /{questions?.length}
              </Typography>

              <div
                style={{
                  height: "70vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  paddingRight: "10px",
                }}
              >
                {scoreData?.length ?scoreData?.map((data, index) => {
                  // const isFlagged = flaggedQuestions.includes(index);

                  return (
                    <div
                      key={index}
                      // style={{ display: "flex", alignItems: "center" }}
                    >
                      <Paper
                        elevation={3}
                        sx={{
                          p: 2,
                          mb: 2,

                          // width: "50%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor:
                            selectedPaperIndex === index
                              ? "#EB568F"
                              : "#f0f0f0",
                          color:
                            selectedPaperIndex === index ? "white" : "black",
                          ml: 2,
                          cursor: "pointer",
                        }}
                        onClick={() => handleQuestionItemClick(index)}
                      >
                        {" "}
                        <div>
                          <Typography variant="subtitle1">
                            Question {index + 1}{" "}
                            {data.isFlagged && "Flagged for Review"}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              color:
                                selectedPaperIndex === index
                                  ? "white"
                                  : "black",
                            }}
                          >
                            Your Answer:{" "}
                            <span
                              style={{
                                color:
                                  selectedPaperIndex === index
                                    ? "white"
                                    : data.isCorrect
                                    ? "green"
                                    : data.givenAnswer
                                    ? "red"
                                    : "red", // Set the color to red for "Not Attempted"
                              }}
                            >
                              {data.givenAnswer
                                ? data?.givenAnswer
                                : "Not Attempted"}
                            </span>
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              color:
                                selectedPaperIndex === index
                                  ? "white"
                                  : "black",
                            }}
                          >
                            {data.reason && `Your Reason: ${data.reason}`}
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            variant="body1"
                            sx={{
                              mr: 5,
                            }}
                          >
                            {data.question}
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              mr: 5,
                            }}
                          >
                            Correct Answer:{" "}
                            <span
                              style={{
                                color:
                                  selectedPaperIndex === index
                                    ? "white"
                                    : "green",
                              }}
                            >
                              {data.correctAnswer}
                            </span>
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              mr: 5,
                            }}
                          >
                            {data.correctReason && (
                              <>
                                Reason:{" "}
                                <span
                                  style={{
                                    color:
                                      selectedPaperIndex === index
                                        ? "white"
                                        : "green",
                                  }}
                                >
                                  {data.correctReason}
                                </span>
                              </>
                            )}
                          </Typography>
                        </div>
                      </Paper>
                    </div>
                  );
                }):loading && (
                  <CircularProgress />
                )}
              </div>
            </div>
            <div
              style={{
                height: "80vh",
              }}
            >
              <Typography variant="subtitle1">
                Question {selectedPaperIndex + 1}:{" "}
                {scoreData?.isFlagged && "Flagged for Review"}
              </Typography>

              <Card
                sx={{
                  width: "100%",
                  height: "100%",
                  paddingTop: "52.25%", // 16:9 aspect ratio
                  position: "relative",
                }}
              >
                <CardMedia
                  component="div"
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                  }}
                >
                  <div
                    className="pan-zoom-container"
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReactPanZoom
                      image={`${ImageBaseUrl}${scoreData?.[selectedPaperIndex]?.image?.[imageIndex]}`}
                      alt={`Question`}
                    />
                  </div>
                </CardMedia>
                {/* <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  {imageIndex > 0 && (
                    <Button
                      // variant="outlined"
                      color="primary"
                      sx={{ backgroundColor: "#ffff" }}
                      onClick={() =>
                        setImageIndex((prevIndex) => prevIndex - 1)
                      }
                    >
                      Previous Image
                    </Button>
                  )}

                  {imageIndex <
                    scoreData?.[selectedPaperIndex]?.image?.length - 1 && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleNextImage}
                      sx={{ ml: 2 }}
                    >
                      Next Image
                    </Button>
                  )}
                </Box> */}
              </Card>
            </div>
          </div>
        </CardContent>
      </div>
    </div>
  );
};

export default Scoreboard;

import React from 'react';
import {TextField } from '@mui/material'
const LongCaseComponent = ({data}) => {
    // console.log(data, "long case component");
    const subheadings = [
        "images",
        "Observations",
        "Interpretation",
        "Diagnosis",
        "Differential",
        "Management",
      ];
  return (
    <div>{subheadings?.map((answer, index) => (
        <div key={index} style={{margin:18}}>
          {answer !== "images" && ( // Exclude "images" subheading
            <TextField
              label={answer}
              multiline
              rows={3}
              value={  data?.longAnswer[answer]||""}
            //   onChange={(e) =>
            //     handleAnswerChange(answer, e.target.value)
            //   }
            // disabled
              fullWidth
            //   variant="outlined"
              InputLabelProps={{ style: { color: "black" } }}
            />
          )}
        </div>
      ))}</div>
  )
}

export default LongCaseComponent
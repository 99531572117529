import dashinactive from "../images/dashboard-ico.svg";
import dashactive from "../images/dashboard-ico2.svg";
import profileactive from "../images/profile-ico2.svg";
import profileinactive from "../images/profile-ico.svg";


const sideBarOptions = [
  {
    title: "Dashboard",
    inactive: dashinactive,
    active: dashactive,
    url: "/dashboard",
    name: "dashboard",
  },
  {
    title: "Analysis",
    url: "/user/analysis",
    inactive: profileinactive,
    active: profileactive,
    name: "analysis",
  },
];

const pages = [
  { title: "Home", url: "/home/id", name: "home" },
  // { title: "About", url: "/about", name: "about" },
  // { title: "Tutorials", url: "/user/tutorials/:id", name: "tutorials" },
  { title: "Analysis", url: "/user/analysis/:id", name: "analysis" },
  // { title: "Flagged Questions", url: "/user/flagged/:id", name: "flagged" },
  // { title: "Reported Questions", url: "/user/reported/:id", name: "reported" },
];

const superAdminMenuItems = [""];
const adminMenuItems = [""];

const userMenuItems = [
  "dashboard",
  "profile",
  "my-files",
  "career-duidance",
  "clinical-programs",
  "my-library",
  "logout",
];

const getTitle = (location) =>
  sideBarOptions.find((d) =>
    location.pathname.toLowerCase().includes(d.url.toLowerCase())
  )?.title || "dashboard";

const ROLES = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  USER: "user",
};
const ROLE_TYPES = {
  super_admin: "Super Admin",
  admin: "Admin",
  user: "User",
};

const SUPER_ADMIN_ROLE = "super_admin";
const ADMIN_ROLE = "admin";
const USER_ROLE = "user";

const RECORD_PERMISSIONS_TYPES = {
  VIEW: "view",
  EDIT: "edit",
  OWNER: "owner",
};
const RECORD_PERMISSIONS = [
  RECORD_PERMISSIONS_TYPES.VIEW,
  RECORD_PERMISSIONS_TYPES.EDIT,
];
const imageExtensions = ["jpg", "jpeg", "png", "gif", "webp", "svg"];

const infoFormFields = [
  {
    label: "Name",
    name: "name",
    type: "text",
  },
  {
    label: "GMC Number",
    name: "gmcRefNumber",
    type: "text",
  },
  {
    label: "College Number",
    name: "collegeNumber",
    type: "text",
  },
  {
    label: "National Training Number",
    name: "nationalTrainingNumber",
    type: "text",
  },
  {
    label: "Email",
    name: "email",
    type: "email",
  },
  {
    label: "JRCPTB Enrolled",
    name: "jrcptbEnrolled",
    type: "selection",
    options: ["Yes", "No"],
  },
];

const addressFormFields = [
  {
    label: "Country",
    name: "address.country",
    type: "text",
  },
  {
    label: "City",
    name: "address.city",
    type: "text",
  },
  {
    label: "State / Province",
    name: "address.state",
    type: "text",
  },
  {
    label: "Postal Code",
    name: "address.postalCode",
    type: "text",
  },
];

export {
  sideBarOptions,
  superAdminMenuItems,
  adminMenuItems,
  userMenuItems,
  getTitle,
  ROLES,
  ROLE_TYPES,
  SUPER_ADMIN_ROLE,
  ADMIN_ROLE,
  USER_ROLE,
  RECORD_PERMISSIONS_TYPES,
  RECORD_PERMISSIONS,
  imageExtensions,
  pages,
  infoFormFields,
  addressFormFields,
};

export const convertToIST = (inputDate) => {
  const date = new Date(inputDate);
  date.setUTCHours(date.getUTCHours() + 5, date.getUTCMinutes() + 30);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed, so add 1
  const year = date.getUTCFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

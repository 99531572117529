import { Typography } from "@mui/material";
import React from "react";

function Tutorials() {
  return (
    <div style={{ margin: "50px" }}>
      <Typography variant="h5" sx={{ color: "#666666" }}>
       Tutorials
      </Typography>

      <Typography mt={4}>
        {" "}
        No Tutorials Available in your package,Buy one{" "}
      </Typography>
    </div>
  );
}

export default Tutorials;

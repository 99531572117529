

const courseService = (httpClient) => ({
  getAllCourse: async () => {
    try {
      const res = await httpClient.get("/course/get-courses");
      return res.data;
    } catch (error) {
      console.error("Error fetching courses:", error);
      return [];
    }
  },
  getEnrollAndUnLockCourse: async () => {
    try {
      const res = await httpClient.get("/course/get-courses-of-user");
      return res.data;
    } catch (error) {
      console.error("Error fetching courses:", error);
      return [];
    }
  },

  enrollCourse: async (courseId) => {
    try {
      const res = await httpClient.post(`/course/enroll/${courseId}`);
      return res.data;
    } catch (error) {
      console.error("Error enrolling in the course:", error);
      return { status: false, message: "Enrollment failed, try again!" };
    }
  },

  getOneCourse: async (courseId) => {
    try {
      const res = await httpClient.get(
        `/course/get-one-course?courseId=${courseId}`
      );
      return res.data;
    } catch (error) {
      console.error(`Error fetching course with ID ${courseId}:`, error);
      return null;
    }
  },

  openCourse: async (courseId) => {
    try {
      const res = await httpClient.post("/course/open-course", { courseId });
      return res.data;
    } catch (error) {
      console.error(`Error opening course with ID ${courseId}:`, error);
      return { status: false, message: "Failed to open course, try again!" };
    }
  },

  openFolder: async (contentId) => {
    try {
      const res = await httpClient.post("/course/open-folder", { contentId });
      return res.data;
    } catch (error) {
      console.error(`Error opening folder with ID ${contentId}:`, error);
      return { status: false, message: "Failed to open folder, try again!" };
    }
  },
  //Analysis
  courseAnalysis: async ( ) => {
    try {
      const res = await httpClient.get("/course/course-analysis-data");
      return res.data;
    } catch (error) { 
      return { status: false, message: "Failed to get data!" };
    }
  },
  mockAnalysis: async (courseId) => {
    try {
      const res = await httpClient.post("/course/mock-analysis-data", { courseId });
      return res.data;
    } catch (error) { 
      return { status: false, message: "Failed to get data!" };
    }
  },
  leaderBoardData: async (courseId) => {
    try {
      const res = await httpClient.post("/course/leader-board-data", { courseId });
      return res.data;
    } catch (error) { 
      return { status: false, message: "Failed to get data!" };
    }
  },
});

export default courseService;

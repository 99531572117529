import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
 
import MenuItem from "@mui/material/MenuItem";
import log from "../../images/newlogo.png";
import { pages } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAuth } from "../../utils/storage-utils";
import { userService } from "../../service";
import { apiConfig } from "../../config/api-config";
import menu from "../../images/menuArrow.svg";
 

const settings = ["Dashboard", "Change Password", "Logout"];

function NavBarTwo() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);
  const [userId, setUserId] = React.useState(null);
  const [session, setSession] = React.useState({});
  const [avatar, setAvatar] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState("");

  useEffect(() => {
    const session = getAuth();
    if (session) {
      setSession(session);
      setAvatar(session.image);
      setIsLoggedIn(true);
      setUserId(session.userId);
    }
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuClick = (e) => {
    if (e === "Logout" || e === "Change Password" || e === "Dashboard") {
      setSelectedItem(e);
      if (e === "Logout") {
        userService.logout();
      } else if (e === "Change Password") {
        navigate(`/user/changepassword/${userId}`);
      } else if (e === "Dashboard") {
        navigate(`/dashboard/${userId}`);
      }
    }
  };

  return (
    <AppBar
      elevation={0}
      position="fixed"
      sx={{ backgroundColor: "white", border: "1px solid #ccc" }}
    >
      <Container
        maxWidth="100vw"
        sx={
          {
            // border: "2px solid red",
          }
        }
      >
        <Toolbar
          sx={{
            "@media  (max-width: 410px)": {
              position: "relative",
              right: "30px",
            },
          }}
        >
          <Box>
            {" "}
            <img
              src={log}
              style={{
                width: "158px",
                height: "49.934px",
              }}
              alt="logo"
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,

              display: { xs: "flex", md: "none", justifyContent: "flex-end" },
              order: [2, 1],
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="black"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "flex", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => {
                    handleCloseNavMenu();
                    userId ? navigate(`${page.url}`) : navigate(page.url);
                  }}
                >
                  <Typography textAlign="center">{page.title}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", justifyContent: "flex-end" },
              mr: "2rem",
            }}
          >
            {pages.map((page) => (
              <Button
                key={page.name}
                onClick={() => {
                  handleCloseNavMenu();
                  userId ? navigate(`${page.url}`) : navigate(page.url);
                  setSelectedItem(page.name); // Update the selected item
                }}
                sx={{
                  my: 2,
                  color: selectedItem === page.name ? "#EB568F" : "black", // Change color based on selection
                  display: "block",
                  textTransform: "none",
                }}
              >
                {page.title}
              </Button>
            ))}
          </Box>
          {/* Conditionally render the buttons based on the presence of id */}
          {isLoggedIn ? (
            <Box
              sx={{ flexGrow: 0, display: "flex", justifyContent: "flex-end" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: "1rem",
                }}
              >
                <Avatar
                  alt="Remy Sharp"
                  src={
                    localStorage.getItem("ePortImage")
                      ? `${localStorage.getItem("ePortImage")}`
                      : `${apiConfig.serverURL}${avatar}`
                  }
                  sx={{
                    mr: "1rem",
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "#000020",
                    }}
                  >
                    {session?.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "10px",
                      fontWeight: "normal",
                      color: "#252525",
                    }}
                  >
                    {session?.status}
                  </Typography>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: "1rem",
                }}
              >
                {/* <Tooltip title="Open settings"> */}
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <img alt="menu" src={menu}></img>
                </IconButton>
                {/* </Tooltip> */}

                <Menu
                  sx={{ mt: "45px", zIndex: "99999" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => {
                        handleMenuClick(setting);
                      }}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              {/* <img src={notifIcon} alt="" /> */}
            </Box>
          ) : (
            <Box>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "30px",
                  color: "#EB568F",
                  borderColor: "#EB568F",
                  textTransform: "none",
                  fontSize: "13px",
                  fontWeight: "500",
                  bgcolor: "white",
                  mr: "1.5rem",
                  width: "auto",
                  height: "2.5rem",

                  "&:hover": {
                    borderColor: "#EB568F",
                    bgcolor: "#EB568F",
                    color: "white",
                  },
                  "@media  (max-width: 650px)": {
                    display: "none",
                  },
                  "@media  (min-width: 900px) and  (max-width: 1035px)": {
                    display: "none",
                  },
                  "@media  (min-width: 800px) and  (max-width: 900px)": {
                    ml: "10rem",
                  },
                  "@media  (min-width: 700px) and  (max-width: 800px)": {
                    ml: "4.9rem",
                  },
                  "@media  (min-width: 650px) and  (max-width: 700px)": {
                    ml: "1.5rem",
                  },
                }}
                onClick={() => {
                  navigate("/Register");
                }}
              >
                Create Account
              </Button>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: "30px",
                  color: "white",
                  borderColor: "#EB568F",
                  textTransform: "none",
                  fontSize: "13px",
                  fontWeight: "500",
                  bgcolor: "#EB568F",
                  mr: "2rem",
                  width: "8rem",
                  height: "2.5rem",

                  "&:hover": {
                    borderColor: "#EB568F",
                    bgcolor: "white",
                    color: "#EB568F",
                  },
                  "@media  (min-width: 500px) and (max-width: 650px)": {
                    ml: "5rem",
                  },
                  "@media  (max-width: 500px)": {
                    ml: "1rem",
                  },
                }}
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                Login
              </Button>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBarTwo;

import {
  Box,
  CardContent,
  IconButton,
  Typography,
  Card,
  CircularProgress,
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import React, { useContext, useEffect, useState } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";
import { examService, resultService } from "../../service";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DrawerComponent from "./Drawer";
import FlaggedQuestions from "../Flagged/FlaggedQuestions";
import ReportedQuestions from "../reported questions/ReportedQuestions";
import { loaderContext } from "../../context";

const FolderOpen = () => {
  const handleBackClick = () => {
    navigate(-1);
  };
  const { drawerActiveLink } = useContext(loaderContext);
  const [openFoldersData, setOpenFolderData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { id, courseId } = useParams();
  useEffect(() => {
    setLoading(true);
    examService.openFolderExam(id).then((res) => {
      if (res.status) {
        setOpenFolderData(res.data);
      }
      setLoading(false);
    });
  }, [id]);
  const handleContinueClick = (examId, questionType, questions) => {
    if (!questions?.length) {
      return Swal.fire("Questions not available", "", "warning");
    }
    Swal.fire({
      title: "Are you ready to start the exam?",
      // text: "Once started, you cannot go back. Make sure you are prepared.",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, start the exam",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        resultService.createResult(examId).then((res) => {
          if (questionType === "Rapid") {
            navigate(`/rapid/${examId}/${res?.data?._id}`);
          } else if (questionType === "Longcase") {
            navigate(`/longcase/${examId}/${res?.data?._id}`);
          } else {
            console.error(`Unknown exam type: ${questionType}`);
          }
        });
      }
    });
  };
  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }
  const StyledText = styled("text")(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: "middle",
    dominantBaseline: "central",
    fontSize: 20,
  }));
  const size = {
    width: 220,
    height: 110,
  };
  const otherCardsData = [
    { value: 20, color: "#EB568F" },
    { value: 80, color: "#ECD1DB" },
  ];
  const cardColors = [
    "linear-gradient(to right, #f5f9c5, #f9f3de, #fbefed)",
    "linear-gradient(to right, #e2ecfc, #f0eeec, #f9efe2)",
    "linear-gradient(to right, #fef3f6, #fff5e7, #fff5df)",
  ];
  return (
    <>
      <DrawerComponent id={courseId} />
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          marginLeft: "200px",
          paddingLeft: 10,
          mt: 5,
        }}
      >
        {drawerActiveLink === "course" && (
          <>
            <div>
              <IconButton
                onClick={handleBackClick}
                sx={{ ml: 3, mt: 2, color: "#1976D2" }}
              >
                <FaArrowLeftLong size={30} />
              </IconButton>
            </div>
            {openFoldersData?.length ? (
              <>
                {openFoldersData?.filter((data) => data.isFolder === false)
                  ?.length ? (
                  <Typography variant="h6" sx={{ m: 2 }}>
                    Exams
                  </Typography>
                ) : (
                  ""
                )}
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {/* {mergedCoursesData
                        .find((course) => course.id === selectedCourse)
                        .exams.map((rapid, index) => ( */}
                  {openFoldersData
                    ?.filter((data) => data.isFolder === false)
                    ?.map((rapid, index) => (
                      <Card
                        key={index}
                        onClick={() =>
                          handleContinueClick(
                            rapid.contentId,
                            rapid.questionType,
                            rapid.questions
                          )
                        }
                        sx={{
                          margin: "10px",
                          width: "15%",
                          borderRadius: "20px",
                          background: cardColors[index % cardColors.length],
                          border: "white",
                          cursor: "pointer",
                          transition: "width 0.3s, height 0.3s",
                          "&:hover": {
                            width: "20%",
                            height: "120%",
                          },
                        }}
                      >
                        <CardContent
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography variant="h5" component="div">
                            {rapid.contentName}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{ color: "#333333" }}
                          >
                            {rapid.questionType}
                          </Typography>
                          <Box sx={{ mt: 2, ml: 12 }}>
                            <PieChart
                              series={[
                                { data: otherCardsData, innerRadius: 30 },
                              ]}
                              {...size}
                            >
                              <PieCenterLabel>
                                {`${otherCardsData?.[0].value}%`}
                              </PieCenterLabel>
                            </PieChart>
                          </Box>
                        </CardContent>
                      </Card>
                    ))}
                </div>
              </>
            ) : loading ? (
              <CircularProgress />
            ) : (
              <Typography>No Record Found</Typography>
            )}
          </>
        )}
        {drawerActiveLink === "flag" && <FlaggedQuestions />}
        {drawerActiveLink === "report" && <ReportedQuestions />}
      </Box>
    </>
  );
};

export default FolderOpen;

import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Button,
  IconButton,
  Modal,
  TableCell,
  TableBody,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  Grid,
  Box,
  CircularProgress,
} from "@mui/material";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { resultService } from "../../service";
import { ImageBaseUrl } from "../../config/api-config";
const LongCaseScoreList = ({
  examId,
  resultId,
  currentQuestion,
  handlePreviousQuestion,
  handleNextQuestion,
  handlePreviousImage,
  handleNextImage,
  imageIndex,
  setImageIndex,
  examName,
}) => {
  const navigate = useNavigate();
  const [quitModalOpen, setQuitModalOpen] = useState(false);
  const [scoreData, setScoreData] = useState([]);
  const subheadings = [
    "Observations",
    "Interpretation",
    "Diagnosis",
    "Differential",
    "Management",
  ];
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //for getting last submitted data
    setLoading(true);
    resultService.longExamResult({ resultId, examId }).then((res) => {
      if (res.status) {
        setScoreData(res.data);
      }
      setLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleExitConfirmationYes = () => {
    navigate(-1); // Replace id with the appropriate value
  };
  const handleExitConfirmationNo = () => {
    // User canceled, close the modal
    setQuitModalOpen(false);
  };

  useEffect(() => {
    setImageIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion]);
  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={10} xl={8}>
          <Paper
            elevation={0}
            sx={{ padding: "20px", mt: 2, width: "100%", textAlign: "center" }}
          >
            <IconButton
              sx={{ position: "absolute", top: 10, left: 10 }}
              onClick={() => setQuitModalOpen(true)}
            >
              <FaArrowLeftLong style={{ color: "#EB568F" }} />
            </IconButton>
            {/* Quit Modal */}
            <Modal
              open={quitModalOpen}
              onClose={() => setQuitModalOpen(false)}
              aria-labelledby="quit-modal"
              aria-describedby="quit-modal-description"
            >
              <Paper
                elevation={5}
                sx={{
                  p: 3,
                  width: 400,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                  Confirm Quit Mock
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Are you sure you want to quit the mock? Your progress will not
                  be saved.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleExitConfirmationYes}
                  sx={{ mr: 2 }}
                >
                  Yes
                </Button>
                <Button variant="contained" onClick={handleExitConfirmationNo}>
                  No
                </Button>
              </Paper>
            </Modal>

            <Typography
              variant="h6"
              sx={{ mb: 0, ml: "3rem", textAlign: "left" }}
            >
              {examName?.charAt(0)?.toUpperCase() + examName?.slice(1)} Solutions
            </Typography>
            <TableContainer
              component={Paper}
              elevation={5}
              sx={{ mt: 2, width: "98%" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: "5%" }}>Question</TableCell>
                    <TableCell sx={{ width: "28%" }}></TableCell>
                    <TableCell sx={{ width: "auto" }}>Your Answers</TableCell>
                    <TableCell sx={{ width: "auto" }}>System Answers</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scoreData.length ? (
                    <>
                      <React.Fragment>
                        <TableRow>
                          <TableCell sx={{ bgcolor: "lightgray" }}>
                            {currentQuestion + 1}
                          </TableCell>
                          <TableCell
                            // colSpan={1}
                            sx={{
                              height: "100px",
                              fontWeight: "bold",
                              position: "relative",
                            }}
                          >
                            <span
                              style={{
                                position: "absolute",
                                width: "50vw",
                                top: "35%",
                              }}
                            >
                              {" "}
                              {scoreData?.[currentQuestion]?.question}
                            </span>
                          </TableCell>
                          <TableCell>
                            {/* <Typography>Your Answer</Typography> */}
                          </TableCell>
                          <TableCell>
                            {/* <Typography>Correct Answer</Typography> */}
                          </TableCell>
                        </TableRow>
                        {subheadings?.map((subheading, subIndex) => (
                          <TableRow key={`${currentQuestion}-${subIndex}`}>
                            <TableCell />
                            <TableCell style={{ height: "80px" }}>
                              {subheading}
                            </TableCell>
                            <TableCell sx={{ color: "#000" }}>
                              {
                                scoreData?.[currentQuestion]?.givenAnswer?.[
                                  subheading
                                ]
                              }
                            </TableCell>
                            <TableCell sx={{ color: "green" }}>
                              {
                                scoreData?.[currentQuestion]?.systemAnswer?.[
                                  subheading
                                ]
                              }
                            </TableCell>
                            <TableCell />
                          </TableRow>
                        ))}
                      </React.Fragment>
                      <TableRow>
                        <TableCell colSpan={5} align="right">
                          <Button
                            variant="contained"
                            onClick={handlePreviousQuestion}
                            disabled={currentQuestion === 0}
                            sx={{ mr: 3 }}
                          >
                            Previous Question
                          </Button>
                          <Button
                            variant="contained"
                            onClick={handleNextQuestion}
                            disabled={currentQuestion === scoreData.length - 1}
                          >
                            Next Question
                          </Button>
                        </TableCell>
                      </TableRow>
                    </>
                  ) :loading && (
                    <div style={{minHeight:'500px'}}>
                      
                      <CircularProgress sx={{mt:'20px'}} />
                    </div>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={4} xl={4}>
          <Box
            sx={{
              border: "1px solid black",
              height: "360px",
              width: "70%",
              mt: 18,
            }}
          >
            {scoreData?.[currentQuestion]?.image?.map(
              (image, index) =>
                index === imageIndex && (
                  <img
                    key={`${index}-${currentQuestion}`}
                    src={`${ImageBaseUrl}${image}`}
                    alt={`Question ${currentQuestion + 1}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                )
            )}
            <Button onClick={handlePreviousImage} disabled={imageIndex === 0}>
              Previous Image
            </Button>
            <Button
              onClick={handleNextImage}
              disabled={
                imageIndex === scoreData?.[currentQuestion]?.image.length - 1
              }
            >
              Next Image
            </Button>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default LongCaseScoreList;

// exam-service.js

// import axios from "axios";

// const examService = (httpClient) => ({
//   submitExam: async (examId) => {
//     try {
//       const response = await httpClient.post(`/exam/submit/${examId}`);
//       return response.data;
//     } catch (error) {
//       console.error(`Error submitting exam with ID ${examId}:`, error);
//       return { status: false, message: "Failed to submit exam" };
//     }
//   },

//   getQuestionDetails: async (questionId) => {
//     try {
//       const response = await httpClient.post(`/question/details/${questionId}`);
//       return response.data;
//     } catch (error) {
//       console.error(`Error fetching details for question with ID ${questionId}:`, error);
//       return { status: false, message: "Failed to fetch question details" };
//     }
//   },

//   answerQuestion: async (questionId) => {
//     try {
//       const response = await httpClient.post(`/question/answer/${questionId}`);
//       return response.data;
//     } catch (error) {
//       console.error(`Error answering question with ID ${questionId}:`, error);
//       return { status: false, message: "Failed to submit answer" };
//     }
//   },

//   nextQuestion: async (examId, questionId, answer) => {
//     try {
//       const response = await httpClient.post(`/question/next/${examId}/${questionId}`, { answer });
//       return response.data;
//     } catch (error) {
//       console.error(`Error moving to next question for exam ${examId}, question ${questionId}:`, error);
//       return { status: false, message: "Failed to move to the next question" };
//     }
//   },

//   toggleFlagQuestion: async (questionId) => {
//     try {
//       const response = await httpClient.post(`/question/toggle-flag/${questionId}`);
//       return response.data;
//     } catch (error) {
//       console.error(`Error toggling flag for question with ID ${questionId}:`, error);
//       return { status: false, message: "Failed to toggle flag for the question" };
//     }
//   },
// });

// export default examService;
const examService = (httpClient) => {
  //open folder
  const viewExamBasedCourse = async (courseId) => {
    try {
      const res = await httpClient.post("/exam/open-course",{courseId});
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch users" };
    }
  };
  const openFolderExam = async (contentId) => {
    try {
      const res = await httpClient.post("/exam/open-folder",{contentId});
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch users" };
    }
  };
  const getOneExam = async (examId,resultId) => {
    try {
      const res = await httpClient.post("/exam/view-exam-page-details",{examId,resultId});
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch users" };
    }
  };

  return {
      viewExamBasedCourse,openFolderExam,getOneExam
  };
};

export default examService;
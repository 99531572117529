import { setAuthStorage, setSessionStorage } from "../utils/storage-utils";

const authService = (httpClient) => ({
  adminSignIn: async (email, password) => {
    try {
      const res = await httpClient.post("/user/auth/sign-in", {
        username: email,
        password,
      });
      return res.data;
    } catch (err) {
      return { status: false, message: "Sign up failed try again!" };
    }
  },

  userSignIn: async (email, password, checked) => {
    try {
      const res = await httpClient.post("/login", {
        email,
        password,
        rememberFlag: checked,
      });
      // console.log("userSignIn\t\t\t", res.data.data);
      if (res.data.data.expiresIn === "1h") {
        setSessionStorage(res.data.data);
      } else {
        setAuthStorage(res.data.data);
      }
      return res.data;
    } catch (err) {
      return { status: false, message: err.response.data.message };
    }
  },
});

export default authService;

import React, { useState } from "react";
import { Paper, Typography, TextField, Button, Grid } from "@mui/material";
import { userService } from "../../service";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const navigate = useNavigate()
  const handleChangePassword = () => {
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    if (!currentPassword?.trim()) {
      setErrorMessage("Please enter current Password");
      return;
    } else if (!newPassword?.trim()) {
      setErrorMessage("Please enter new Password");
      return;
    } else if (!passwordRegex.test(newPassword)) {
      setErrorMessage(
        "Password must be at least 6 characters long with alphabets, numbers, and special characters."
      );
    } else if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    } else {
      setErrorMessage("");
    }
    // Add logic to handle password change
    // console.log("Changing password...", currentPassword, newPassword);
    userService.changePassword(currentPassword, newPassword).then((res) => {
      if(res.status){
        snackbar("Password changed successfully", { variant: "success" ,anchorOrigin:{ horizontal: 'left', vertical: 'bottom' }}); 
        navigate(-1)
      }else{
        setErrorMessage(res.message)
      }
    });
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Paper elevation={3} style={{ padding: 20, maxWidth: 400 }}>
        <Typography variant="h5" align="center" gutterBottom>
          Change Password
        </Typography>

        <TextField
          fullWidth
          label="Current Password"
          type="password"
          margin="normal"
          variant="outlined"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
        />

        <TextField
          fullWidth
          label="New Password"
          type="password"
          margin="normal"
          variant="outlined"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />

        <TextField
          fullWidth
          label="Confirm New Password"
          type="password"
          margin="normal"
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <p style={{ color: "red", fontSize: "12px" }}>{errorMessage}</p>
        <Button
          variant="contained"
          fullWidth
          style={{ marginTop: 20 }}
          onClick={handleChangePassword}
          sx={{ bgcolor: "#EB568F" }}
        >
          Change Password
        </Button>
      </Paper>
    </Grid>
  );
}

export default ChangePassword;

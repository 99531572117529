import React, { useState, useEffect } from "react";
import {
  Typography,
  Card,
  CardContent,
  Divider,
  useTheme,
  TextField,
  CardMedia,
  Paper,
  IconButton,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import { flagService } from "../../service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import LongCaseComponent from "../Flagged/LongCaseComponent";
import { ImageBaseUrl } from "../../config/api-config";

const ReportedQuestions = () => {
  const theme = useTheme();
  const [displayedQuestion, setDisplayedQuestion] = useState(null);
  const [reportedQuestions, setReportedQuestions] = useState([]);
  const [imageIndex, setImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    flagService.getFullReportedQuestions().then((res) => {
      if (res.status) {
        setReportedQuestions(res.data);
      }
      setLoading(false);
    });
  }, []);
  const handleCardClick = (question) => {
    setDisplayedQuestion(question);
  };

  const handleBackButtonClick = () => {
    setDisplayedQuestion(null);
  };
  const handleNextImage = () => {
    if (imageIndex < displayedQuestion?.image?.length - 1) {
      setImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <>
      {displayedQuestion ? (
        <Paper sx={{ p: 1, mr: 32 }}>
          {/* <Divider sx={{ my: 2 }} /> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ width: "50%" }}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <IconButton onClick={handleBackButtonClick} sx={{ mb: 0 }}>
                  <ArrowBackIcon style={{ color: "#EB568F" }} />
                </IconButton>
              </div>
              <Typography variant="body1">
                {displayedQuestion.question}
              </Typography>
              {displayedQuestion?.questionType === "Longcase" ? (
                <>
                  <LongCaseComponent data={displayedQuestion} />
                </>
              ) : (
                <>
                  <TextField
                    // select
                    label="Correct Answer"
                    value={displayedQuestion?.radAnswer || ""}
                    // onChange={(e) => setQuestionType(e.target.value)}
                    sx={{ width: "300px", mt: 2, color: "black" }}
                    // disabled
                  >
                    {/* <MenuItem   disabled>
                  Select Normal/Abnormal
                </MenuItem>
                <MenuItem value="Normal">Normal</MenuItem>
                <MenuItem value="Abnormal">Abnormal</MenuItem> */}
                  </TextField>
                  {displayedQuestion?.radAnswer === "Abnormal" && (
                    <TextField
                      label="Reason for Abnormal"
                      value={displayedQuestion?.radReason || ""}
                      // onChange={(e) => setAbnormalReason(e.target.value)}
                      multiline
                      rows={5}
                      sx={{ mt: 3, width: "500px" }}
                      // disabled
                    />
                  )}
                </>
              )}
            </div>
            <Card
              sx={{
                width: "50%",
                height: "50%",
                paddingTop: "52.25%", // 16:9 aspect ratio
                position: "relative",
              }}
            >
              <CardMedia
                component="div"
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                }}
              >
                <div
                  className="pan-zoom-container"
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ReactPanZoom
                    image={`${ImageBaseUrl}${displayedQuestion?.image?.[imageIndex]}`}
                    alt={`Question`}
                  />
                </div>
              </CardMedia>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                {imageIndex > 0 && (
                  <Button
                    // variant="outlined"
                    color="primary"
                    sx={{ backgroundColor: "#ffff" }}
                    onClick={() => setImageIndex((prevIndex) => prevIndex - 1)}
                  >
                    Previous Image
                  </Button>
                )}

                {imageIndex < displayedQuestion?.image?.length - 1 && (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleNextImage}
                    sx={{ ml: 2 }}
                  >
                    Next Image
                  </Button>
                )}
              </Box>
            </Card>
          </div>
        </Paper>
      ) : (
        <div style={{ padding: "40px" }}>
          <Typography variant="h5" sx={{ color: "#666666" }}>
            Reported Questions
          </Typography>

          {/* Display reported questions with admin responses */}
          {reportedQuestions?.length > 0 ? (
            reportedQuestions?.map((item, index) => (
              <Card
                key={index}
                sx={{
                  mt: 2,
                  width: "70%",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  transition: "box-shadow 0.3s ease",
                  "&:hover": {
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
                  },
                  cursor: "pointer",
                }}
                onClick={() => handleCardClick(item)}
              >
                <CardContent sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    variant="h6"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    {item.examName} - {item.questionType}
                  </Typography>
                  <Typography variant="body1">
                    Question {index + 1}: {item.question}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Typography variant="body2" sx={{ mb: 2 }}>
                    Reason for Reporting: {item.reportReason}
                  </Typography>
                  <Typography>
                    Mentor Response:
                    {item.adminResponse ? item.adminResponse : " Pending..."}
                  </Typography>
                </CardContent>
                <Divider />
              </Card>
            ))
          ) : loading ? (
            <CircularProgress sx={{mt:3}} />
          ) : (
            <Typography>No Record Found</Typography>
          )}
        </div>
      )}
    </>
  );
};

export default ReportedQuestions;

import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
  InputAdornment,
  IconButton,
  Grid,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { userService } from "../../service/index";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useSnackbar } from "notistack";
import styled from "@emotion/styled";
import img2 from "../../images/StudyMedic_logo.png";

const StyledImg2 = styled("img")({
  width: "150px",
  height: "46px",
  marginTop: "60px",
  marginLeft: "80px",
});

function Register() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [reenteredPassword, setReenteredPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const[name,setName]=useState('')
  const { enqueueSnackbar: snackbar } = useSnackbar();
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isFormValid) return;
    setLoading(true);

    userService.createUser(email, password, phoneNumber,name).then((res) => {
      setLoading(false);
      if (!res.status) {
        // snackbar("Registration Failed", { variant: "error" });
        snackbar( "Email already in use", { variant: "error" });
        return;
      }
      snackbar("Registration Successful", { variant: "success" });

      if (res.status) {
        navigate("/sign-in/");
        return;
      }
    });
  };

  const updateFormValidity = () => {
    setIsFormValid(
      emailValid &&
        validatePassword(password) &&
        isPasswordMatch(password, reenteredPassword) &&
        validatePhoneNumber(phoneNumber) &&
        isCheckboxChecked
    );
  };

  const handleCheckboxChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
  };

  useEffect(() => {
    updateFormValidity();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailValid, password, reenteredPassword, phoneNumber, isCheckboxChecked]);

  const isPasswordMatch = (password, reenteredPassword) => {
    return password === reenteredPassword;
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailValid(validateEmail(newEmail));
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    return passwordRegex.test(password);
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setIsPasswordValid(validatePassword(newPassword));
  };

  const handleReenteredPasswordChange = (event) => {
    const newReenteredPassword = event.target.value;
    setReenteredPassword(newReenteredPassword);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\d{12}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handlePhoneNumberChange = (value, data, event, formattedValue) => {
    setPhoneNumber(value);
    setIsPhoneNumberValid(validatePhoneNumber(value));
  };

  return (
    <>
      <Box>
        <StyledImg2
          src={img2}
          style={{ width: "140px", height: "60px", marginTop: 0 }}
          alt="logo"
        />
      </Box>
      <Grid
        container
        component="main"
        maxWidth="xs"
        sx={{
          display: "flex",
          overflow: "hidden",
          justifyContent: "center",
        }}
      >
        <Grid
          item
          xs={12}
          sm={3.5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            background: "none",
            zIndex: 2,
            padding: 3,
            position: "relative",
            minHeight: "100%",
            overflow: "hidden",
            mt: -4,
          }}
        >
          <Typography
            sx={{
              fontSize: "34px",
              color: "#2C2C83",
              ":hover": { color: "#EB568F" },
              mb: 2,
            }}
          >
            Register
          </Typography>
          {/* Name field */}
          <TextField
            variant="outlined"
            fullWidth
            label="Full name"
            value={name}
            onChange={(e)=>{setName(e.target.value)}}
            sx={{
              mb: 2,
            }}
          />
          {/* Phone number field */}

          <PhoneInput
            country={"in"}
            onChange={handlePhoneNumberChange}
            placeholder="Enter phone number"
            inputStyle={{
              border: "1px solid darkgrey",
              width: "100%",
              height: "50px",
              backgroundColor: isPhoneNumberValid ? "white" : "#FFEAE8",
              borderRadius: "-5px",
              paddingLeft: "100px",
            }}
            buttonStyle={{
              height: "50px",
              border: "1px solid darkgrey",
              backgroundColor: "white",
              width: "88px",
              paddingLeft: "20px",
            }}
          />

          {/* Email field */}
          <TextField
            variant="outlined"
            fullWidth
            label="Email"
            sx={{
              mt: 2,
            }}
            onChange={handleEmailChange}
            value={email}
            error={!emailValid}
            helperText={!emailValid && "Invalid email address"}
          />

          {/* Password field */}
          <TextField
            variant="outlined"
            fullWidth
            label="Password"
            type={showPassword ? "text" : "password"}
            sx={{
              mt: 2,
            }}
            onChange={handlePasswordChange}
            value={password}
            error={!isPasswordValid}
            helperText={
              !isPasswordValid &&
              "Password must be at least 6 characters long with alphabets, numbers, and special characters."
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Re-enter Password */}
          <TextField
            variant="outlined"
            fullWidth
            label="Re-enter Password"
            type={showPassword ? "text" : "password"}
            sx={{
              mt: 2,
            }}
            onChange={handleReenteredPasswordChange}
            value={reenteredPassword}
            error={!isPasswordMatch(password, reenteredPassword)}
            helperText={
              !isPasswordMatch(password, reenteredPassword) &&
              "Passwords do not match"
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {/* Password condition */}
          <Typography
            variant="h6"
            sx={{
              mt: 2,
              fontSize: "9px",
              textAlign: "justify",
              wordSpacing: "-1px",
              lineHeight: "16px",
              fontWeight: "400",
            }}
          >
            (Password must be at least 6 characters long which can be alphabets,
            numbers, or special characters, or a mix of all three respectively)
          </Typography>

          {/* Checkbox */}
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mt: -2, ml: 2 }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  sx={{
                    color: "#252525",
                    "&.Mui-checked": { color: "#EB568F" },
                    mt: 3,
                  }}
                  checked={isCheckboxChecked}
                  onChange={handleCheckboxChange}
                />
              }
              label={
                <Typography sx={{ fontSize: "13px", mt: 3 }}>
                  Accept Terms & Conditions{" "}
                </Typography>
              }
            />
          </Box>

          {/* Register Button */}
          <Button
            variant="contained"
            onClick={handleSubmit}
            disableElevation
            sx={{
              borderRadius: "10px",
              bgcolor: "#EB568F",
              width: "100%",
              mt: 0,
              fontSize: "14px",
              textTransform: "none",
              height: "46px",

              "&:hover": { bgcolor: "#EB568F" },
            }}
            disabled={!isFormValid}
          >
            Register
          </Button>

          {/* Login Link */}
          <Typography
            sx={{
              mt: 1,

              fontSize: "14px",

              color: "#8890A7",
              ":hover": { color: "#EB568F" },
            }}
          >
            You have an account?
            <Link
              onClick={() => {
                navigate("/sign-in");
              }}
              color="#EB568F"
              sx={{ ml: 1, cursor: "pointer" }}
            >
              Login
            </Link>
          </Typography>
          <Box>
            <Typography
              sx={{
                fontSize: "14px",
                mt: 3,
                color: "black",
                ":hover": { color: "#EB568F" },
              }}
            >
              © 2023 StudyMEDIC
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Register;

import React from "react";
import { Route, Routes } from "react-router-dom";
import { apiConfig } from "../config/api-config";
import Template from "../components/template/template";
import UserSignIn from "../pages/sign-in/Login";
import UserRegister from "../pages/Register/Register";
import FourNotFour from "../pages/FourNotFour";
import DashboardTwo from "../pages/dashboard/Dashboard";
import Home from "../pages/dashboard/Home";
import Flagged from "../pages/Flagged/FlaggedQuestions";
import Reported from "../pages/reported questions/ReportedQuestions";
import Tutorials from "../pages/Tutorials/Tutorials";
import Packages from "../components/packages/packages";
import Rapid from "../components/rapid/Rapid";
import LongCase from "../components/longcase/LongCase";
import Analysis from "../pages/Analysis/Analysis";
import ChangePassword from "../pages/change password/ChangePassword";
import FolderOpen from "../pages/dashboard/FolderOpen";

const Routers = () => {
  return (
    <Routes>
      <Route path={`${apiConfig.basePath}/sign-in`} element={<UserSignIn />} />
      <Route
        path={`${apiConfig.basePath}/register`}
        element={<UserRegister />}
      />
      <Route
        path={`${apiConfig.basePath}/`}
        element={
          <Template>
            <UserSignIn />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/packages/:id`}
        element={
          <Template>
            <Packages />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/dashboard/:id`}
        element={
          <Template>
            <DashboardTwo />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/folder/:courseId/:id`}
        element={
          <Template>
            <FolderOpen />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/course/:id`}
        element={
          <Template>
            <DashboardTwo />
          </Template>
        }
      />

      <Route
        path={`${apiConfig.basePath}/home/:id`}
        element={
          <Template>
            <Home />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/user/analysis/:id`}
        element={
          <Template>
            <Analysis />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/user/flagged/:id`}
        element={
          <Template>
            <Flagged />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/user/reported/:id`}
        element={
          <Template>
            <Reported />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/user/tutorials/:id`}
        element={
          <Template>
            <Tutorials />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/rapid/:examId/:resultId`}
        element={
          <Template>
            <Rapid />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/longcase/:examId/:resultId`}
        element={
          <Template>
            <LongCase />
          </Template>
        }
      />
      <Route
        path={`${apiConfig.basePath}/user/changepassword/:id`}
        element={
          <Template>
            <ChangePassword />
          </Template>
        }
      />
      <Route path="*" element={<FourNotFour />} />
    </Routes>
  );
};

export default Routers;

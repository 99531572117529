import { Box, Drawer, List, ListItem, ListItemText } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { FiFlag, FiHome } from "react-icons/fi";
import { GoReport } from "react-icons/go";
import { PiBookOpenText } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { loaderContext } from "../../context";
import { courseService } from "../../service";

const DrawerComponent = ({ id }) => {
  const navigate = useNavigate();
  const [showCoursesDropdown, setShowCoursesDropdown] = useState(true);
  const { drawerActiveLink, setDrawerActiveLink } = useContext(loaderContext);
  const [coursesData, setCourseData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(1);
  const lockChecking = (isLocked, isPurchased) => {
    if (!isLocked || (isLocked && isPurchased)) {
      return false;
    } else {
      return true;
    }
  };
  const handleCoursesDropdownClick = () => {
    setShowCoursesDropdown(!showCoursesDropdown);
    setDrawerActiveLink("course");
  };

  const handleFlaggedQuestionsClick = () => {
    setDrawerActiveLink("flag");
  };
  const handleReportedQuestionsClick = () => {
    setDrawerActiveLink("report");
  };
  const handleCourseClick = (courseId) => {
    setDrawerActiveLink("course");
    navigate(`/course/${courseId}`);
    // if (courseId !== "dashboard")
    // examService.viewExamBasedCourse(courseId).then((res) => {
    //   if (res.status) {
    //     setOpenCourseData(res.data);
    //   }
    // });
    setSelectedCourse(courseId);
    // setOpenFolder(null);
    // setShowFlaggedQuestions(false);
    // setIsDrawerOpen(true);
    // setShowReportedQuestions(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    courseService.getAllCourse().then((res) => {
      if (res.status) {
        setSelectedCourse(id);
        setDrawerActiveLink("course");
        setCourseData(res.data);
       
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <div>
      <Drawer variant="permanent" anchor="left">
        <Box
          sx={{
            width: "236px",
            p: 1,
            background: "#F0F4F9",
            height: "100vh",
          }}
        >
          <ListItem
            button
            onClick={() => navigate(`/home/id`)}
            sx={{
              bgcolor: drawerActiveLink === "home" ? "#F0F4F9" : "inherit",
              mt: 10,
              mb: 3,
            }}
          >
            <FiHome
              size={20}
              style={{
                marginRight: "10px",
                color: drawerActiveLink === "home" ? "#EB568F" : "#1976D2",
              }}
            />
            <ListItemText
              sx={{
                color: drawerActiveLink === "home" ? "#EB568F" : "black",
                mt: 1,
              }}
              primary="Home"
            />
          </ListItem>

          <ListItem
            button
            onClick={handleCoursesDropdownClick}
            sx={{
              display: "flex",
              alignItems: "center",
              bgcolor: drawerActiveLink === "course" ? "#F0F4F9" : "inherit",
            }}
          >
            <PiBookOpenText
              size={20}
              style={{
                marginRight: "10px",
                color: drawerActiveLink === "course" ? "#EB568F" : "#1976D2",
              }}
            />
            <ListItemText primary="Courses" />
            <BsChevronDown
              style={{
                color: drawerActiveLink === "course" ? "#EB568F" : "#1976D2",
              }}
              size={20}
            />
          </ListItem>

          {showCoursesDropdown && (
            <List>
              {coursesData?.map((course, index) => {
                if (!lockChecking(course.isLocked, course.isPurchased)) {
                  return (
                    <ListItem
                      button
                      key={index}
                      onClick={() => handleCourseClick(course.courseId)}
                      sx={{
                        bgcolor:
                          selectedCourse === course.courseId &&
                          drawerActiveLink === "course"
                            ? "#F0F4F9"
                            : "inherit",
                        color:
                          selectedCourse === course.courseId &&
                          drawerActiveLink === "course"
                            ? "#EB568F"
                            : "black",
                      }}
                    >
                      <ListItemText primary={course.courseName} />
                    </ListItem>
                  );
                }
                return <div key={index}></div>;
              })}
            </List>
          )}

          <ListItem
            button
            onClick={handleFlaggedQuestionsClick}
            sx={{
              bgcolor: drawerActiveLink === "flag" ? "#F0F4F9" : "inherit",
              color: drawerActiveLink === "flag" ? "#EB568F" : "black",
              mt: 3,
            }}
          >
            <FiFlag
              size={20}
              style={{
                marginRight: "10px",
                color: drawerActiveLink === "flag" ? "#EB568F" : "#1976D2",
              }}
            />
            <ListItemText primary="Flagged Questions" />
          </ListItem>
          <ListItem
            button
            onClick={() => handleReportedQuestionsClick()}
            sx={{
              bgcolor: drawerActiveLink === "report" ? "#F0F4F9" : "inherit",
              color: drawerActiveLink === "report" ? "#EB568F" : "black",
              mt: 3,
            }}
          >
            <GoReport
              size={20}
              style={{
                marginRight: "10px",
                color: drawerActiveLink === "report" ? "#EB568F" : "#1976D2",
              }}
            />
            <ListItemText primary="Reported Questions" />
          </ListItem>
        </Box>
      </Drawer>
    </div>
  );
};

export default DrawerComponent;

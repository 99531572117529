import React, { useState } from "react";

import {
  Container,
  Typography,
  Card,
  CardContent,
  Button,
  Grid,
  Snackbar,
  Alert,
  Divider,
  List,
  ListItem,
  Modal,
  Box,
  TextField,
  IconButton,
  useTheme,
  Radio,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import {
  FaTrash,
  FaShare,
  FaInstagram,
  FaFacebookF,
  FaArrowLeft,
  FaCopy,
} from "react-icons/fa";
import { FaCartShopping, FaLinkedinIn } from "react-icons/fa6";
import { IoMdClose } from "react-icons/io";
import package1 from "../../images/package1.jpg";
import package2 from "../../images/package2.jpg";
import { IoShareSocialSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

const Packages = () => {
  const theme = useTheme();
  const [cartOpen, setCartOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [couponCode, setCouponCode] = useState("");
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [productLink ] = useState(
    "https://dummy-package-link.com"
  );
  const [checkoutView, setCheckoutView] = useState(false);
  const [selectedOption, setSelectedOption] = useState("card");
  const [showBackIcon, setShowBackIcon] = useState(true);

  const handleOptionChange = (event) => {
    const newValue =
      selectedOption === event.target.value ? null : event.target.value;
    setSelectedOption(newValue);
  };

  const packagesData = [
    {
      name: "Basic Package",
      price: 19.99,
      details: "Includes basic features",
      bags: 10,
      cases: 2,
      image: package1,
    },
    {
      name: "Premium Package",
      price: 29.99,
      details: "Includes premium features",
      bags: 20,
      cases: 5,
      image: package2,
    },
  ];

  const copyToClipboard = () => {
    navigator.clipboard.writeText(productLink);
  };

  const handleAddToCart = (packageItem) => {
    const isAlreadyInCart = cartItems.some(
      (item) => item.name === packageItem.name
    );

    if (isAlreadyInCart) {
      setSnackbarMessage("Package already added to cart");
    } else {
      setCartItems([...cartItems, { ...packageItem, coupon: "", share: "" }]);
      setSnackbarMessage("Added to cart successfully");
    }

    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleRemoveFromCart = (index) => {
    const updatedCart = [...cartItems];
    updatedCart.splice(index, 1);
    setCartItems(updatedCart);
  };

  const handleApplyCoupon = (index) => {
    // Implement logic to apply coupon code to the specific package
    // Update the coupon property in the cartItems state
  };

  const handleShareModalOpen = (index) => {
    setShareModalOpen(true);
  };

  const handleShareModalClose = () => {
    setShareModalOpen(false);
  };

  const calculateTotalAmount = () => {
    return cartItems.reduce((total, item) => total + item.price, 0);
  };

  const handleCartIconClick = () => {
    setCartOpen(!cartOpen);
    setCheckoutView(false);
    setShowBackIcon(true); // Hide the back icon when cart is opened
  };

  // const handleBackToPackages = () => {
  //   setCartOpen(false);
  //   setCheckoutView(false);
  //   setShowBackIcon(true); // Show the back icon when going back to packages
  // };

  const handleProceedToBuy = () => {
    setCheckoutView(true);
    setShowBackIcon(true); // Hide the back icon when proceeding to buy
  };

  return (
    <Container sx={{ margin: 6 }}>
      {showBackIcon && ( // Check the showBackIcon state
        <Link to="/dashboard/:id">
          <FaArrowLeft
            style={{
              fontSize: "24px",
              cursor: "pointer",

              color: theme.colors.primary.default,
            }}
          />
        </Link>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h5" sx={{ mt: 2, color: "#666666" }}>
          Packages
        </Typography>
        {(!cartOpen || checkoutView) && (
          <FaCartShopping
            style={{
              fontSize: "24px",
              marginLeft: "auto",
              cursor: "pointer",
              color: theme.colors.primary.default,
            }}
            onClick={handleCartIconClick}
          />
        )}
      </div>

      {cartOpen && !checkoutView ? (
        <div>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Your Cart
          </Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />

          <List>
            {cartItems.map((item, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={item.image}
                        alt={item.name}
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                          marginRight: "10px",
                        }}
                      />
                      {item.name} - {item.price}
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          marginRight: 6,
                          bgcolor: "#666666",
                        }}
                        onClick={() => handleShareModalOpen(index)}
                      >
                        <FaShare style={{ marginRight: "10px" }} />
                        Share
                      </Button>
                      <IconButton
                        edge="end"
                        aria-label="remove"
                        onClick={() => handleRemoveFromCart(index)}
                      >
                        <FaTrash />
                      </IconButton>
                    </div>
                  </div>
                </ListItem>
                <ListItem sx={{ mt: 3 }}>
                  <TextField
                    label="Coupon Code"
                    variant="outlined"
                    size="small"
                    value={item.coupon}
                    onChange={(e) =>
                      setCouponCode(e.target.value, handleApplyCoupon(index))
                    }
                  />
                  <Button
                    variant="contained"
                    size="small"
                    sx={{
                      marginLeft: 2,
                      bgcolor: theme.colors.primary.default,
                    }}
                    onClick={() => handleApplyCoupon(index)}
                  >
                    Apply Coupon
                  </Button>
                </ListItem>
                {index < cartItems.length - 1 && (
                  <Divider variant="middle" sx={{ mt: 5, mb: 2 }} />
                )}
              </React.Fragment>
            ))}
          </List>
          <Divider variant="middle" sx={{ mt: 5, mb: 2 }} />
          <ListItem
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Total Amount : </Typography>
            <Typography variant="h5">{calculateTotalAmount()}</Typography>
          </ListItem>
          <Grid container justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                mt: 2,
                mb: 2,
                bgcolor: theme.colors.primary.default,
              }}
              onClick={handleProceedToBuy}
            >
              <Typography variant="body2" sx={{ p: 1 }}>
                Proceed to Buy ({cartItems.length} item
                {cartItems.length !== 1 ? "s" : ""})
              </Typography>
            </Button>
          </Grid>
        </div>
      ) : checkoutView ? (
        <div>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Checkout
          </Typography>
          <Divider sx={{ mt: 1, mb: 2 }} />

          <FormControl component="fieldset">
            <FormControlLabel
              value="card"
              control={<Radio />}
              label="Credit or Debit Card"
              checked={selectedOption === "card"}
              onChange={handleOptionChange}
              sx={{ mb: 1 }}
            />
            <FormControlLabel
              value="netBanking"
              control={<Radio />}
              label="Net Banking"
              checked={selectedOption === "netBanking"}
              onChange={handleOptionChange}
              sx={{ mb: 1 }}
            />
            <FormControlLabel
              value="upiApps"
              control={<Radio />}
              label="Other UPI Apps"
              checked={selectedOption === "upiApps"}
              onChange={handleOptionChange}
              sx={{ mb: 1 }}
            />
            <FormControlLabel
              value="emi"
              control={<Radio />}
              label="EMI"
              checked={selectedOption === "emi"}
              onChange={handleOptionChange}
              sx={{ mb: 1 }}
            />
          </FormControl>
        </div>
      ) : (
        <Grid container spacing={3} mt={4}>
          {packagesData.map((packageItem, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card>
                <img
                  src={packageItem.image}
                  alt={packageItem.name}
                  style={{
                    width: "100%",
                    height: "150px",
                    objectFit: "cover",
                  }}
                />

                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" component="div" sx={{ mb: 2 }}>
                      {packageItem.name}
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      ${packageItem.price}
                    </Typography>
                  </div>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    {packageItem.details}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2 }}
                  >
                    Bags: {packageItem.bags}, Cases: {packageItem.cases}
                  </Typography>

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ bgcolor: theme.colors.primary.default, mr: "10px" }}
                    onClick={() => handleAddToCart(packageItem)}
                  >
                    Add to Cart
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ bgcolor: theme.colors.primary.default }}
                    onClick={handleProceedToBuy}
                  >
                    Buy Now
                  </Button>

                  <IoShareSocialSharp
                    style={{
                      marginLeft: "16rem",
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleShareModalOpen(index)}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        sx={{ ml: "28rem" }}
      >
        <Alert onClose={handleSnackbarClose} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Modal open={shareModalOpen} onClose={handleShareModalClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IoMdClose
            onClick={handleShareModalClose}
            cursor={"pointer"}
            style={{
              width: "20px",
              height: "20px",
              marginLeft: "20rem",
            }}
          />

          <Typography variant="h6">Share this package with friends</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Package Link"
            value={productLink}
            InputProps={{
              endAdornment: (
                <IconButton onClick={copyToClipboard}>
                  <FaCopy style={{ color: theme.colors.primary.default }} />
                </IconButton>
              ),
            }}
          />
          <div>
            <IconButton>
              <FaInstagram style={{ color: theme.colors.primary.default }} />
            </IconButton>
            <IconButton>
              <FaFacebookF style={{ color: theme.colors.primary.default }} />
            </IconButton>
            <IconButton>
              <FaLinkedinIn style={{ color: theme.colors.primary.default }} />
            </IconButton>
          </div>
        </Box>
      </Modal>
    </Container>
  );
};

export default Packages;

const flagService = (httpClient) => {
  //report
  const getFullReportedQuestions = async () => {
    try {
      const res = await httpClient.get("/question/get-report-question");
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch  " };
    }
  };
  //flag
  const getFullFlaggedQuestions = async () => {
    try {
      const res = await httpClient.get("/question/get-flag-question");
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch  " };
    }
  };
  const getFirstFlagQuestion = async (contentId) => {
    try {
      const res = await httpClient.get("/question/get-first-flag-question", {
        contentId,
      });
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch  " };
    }
  };
  const nextFlagQuestionAndSubmission = async (body) => {
    try {
      const res = await httpClient.post(
        "/question/submit-and-next-flag-question",
        body
      );
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch  " };
    }
  };
  const getFirstFlagQuestionBasedOnExam = async (examId) => {
    try {
      const res = await httpClient.post(
        "/question/get-first-flag-question-based-exam",
        { examId }
      );
      return res.data;
    } catch (error) {
      return { status: false, message: "Failed to fetch  " };
    }
  };

  return {
    getFullFlaggedQuestions,
    getFirstFlagQuestion,
    nextFlagQuestionAndSubmission,
    getFirstFlagQuestionBasedOnExam,
    getFullReportedQuestions,
  };
};

export default flagService;

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  IconButton,
  Card,
  CardContent,
  MenuItem,
  TextField,
  Modal,
  Slider,
  Menu,
  Snackbar,
} from "@mui/material";
import { GoReport } from "react-icons/go";
import { RiCloseFill } from "react-icons/ri";
import { FaFlag } from "react-icons/fa";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import { examService, questionService, resultService } from "../../service";
import { ImageBaseUrl } from "../../config/api-config";
import LongCaseScoreList from "./LongCaseScoreList";

const LongCase = () => {
  const navigate = useNavigate();
  let { examId, resultId } = useParams();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [contrast, setContrast] = useState(1);
  const [brightness, setBrightness] = useState(100);
  const [timer, setTimer] = useState(1800); // 30 minutes in seconds
  const [scoreBoardVisible, setScoreBoardVisible] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedImageIndex] = useState(0);
  const [reportModalOpen, setReportModalOpen] = useState(false);
  const [buttonColor] = useState("");
  const [quitModalOpen, setQuitModalOpen] = useState(false);
  const [showReportReply, setShowReportReply] = useState(false);
  const [questionData, setQuestionsData] = useState({});
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [isRefresh, setIsRefresh] = useState(false);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentAnswer, setCurrentAnswer] = useState({
    isFlagged: false,
    reportReason: "",
    questionId: "",
    Observations: "",
    Interpretation: "",
    Diagnosis: "",
    Differential: "",
    Management: "",
  });
  const handleNextImage = () => {
    if (imageIndex < questionData?.image?.length - 1) {
      setImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  // for question listing
  useEffect(() => {
    examService.getOneExam(examId, resultId).then((res) => {
      if (res.status) {
        setQuestions(res.data);
        if (res.data?.[0]?.timer) {
          setTimer(res.data?.[0]?.timer);
        }
        questionService
          .getOneQuestion(res.data?.[0]?.questions?.[currentQuestion])
          .then((res) => {
            if (res.status) {
              setQuestionsData(res.data);
              setCurrentAnswer((prev) => ({
                ...prev,
                questionId: res.data?._id,
              }));
              setIsRefresh(!isRefresh);
            }
          });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //  for question data
  useEffect(() => {
    if (questions?.length) {
      questionService
        .getOneQuestion(questions?.[0]?.questions?.[currentQuestion])
        .then((res) => {
          if (res.status) {
            setQuestionsData(res.data);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestion]);
  const [showSolution, setShowSolution] = useState(
    Array(questions.length).fill(false)
  );

  const handleViewSolution = (index) => {
    setShowSolution((prevShowSolution) => {
      const updatedShowSolution = [...prevShowSolution];
      updatedShowSolution[index] = !updatedShowSolution[index];
      return updatedShowSolution;
    });
  };
  const subheadings = [
    "images",
    "Observations",
    "Interpretation",
    "Diagnosis",
    "Differential",
    "Management",
  ];

  const handleAnswerChange = (key, value) => {
    setCurrentAnswer((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleEndExam = () => {
    const questionId = questions?.[0]?.questions?.[currentQuestion];
    currentAnswer.questionId = questionId;
    currentAnswer.isExamFinished = true;
    //api function for save  the user's answer to database
    setLoading(true);
    handleQuestionSubmit(currentAnswer, true);

    // setScoreBoardVisible(true);
  };

  const handleContrastChange = (event, newValue) => {
    setContrast(newValue);
  };

  const handleBrightnessChange = (event, newValue) => {
    setBrightness(newValue);
  };
  // for getting answers data when refresh
  useEffect(() => {
    resultService.getAllLongAnswers({ resultId }).then((res) => {
      if (res.status) {
        if (res.data?.[0]?.isExamFinished) {
          setScoreBoardVisible(true);
        } else {
          const answersData = res.data;

          const finalData = answersData?.map((data) => {
            if (!data.reportReason) data.reportReason = "";
            if (!data.isFlagged) data.isFlagged = false;
            if (!data.Observations) data.Observations = "";
            if (!data.Interpretation) data.Interpretation = "";
            if (!data.Diagnosis) data.Diagnosis = "";
            if (!data.Differential) data.Differential = "";
            if (!data.Management) data.Management = "";
            delete data.isExamFinished;
            return data;
          });
          setAnswers(finalData);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // //for setting first data answer when refresh
  useEffect(() => {
    let questionId = questions?.[0]?.questions?.[currentQuestion];
    let changeExitIndex = answers?.findIndex(
      (data) => data?.questionId === questionId
    );
    if (changeExitIndex !== -1) {
      setCurrentAnswer(answers[changeExitIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefresh]);
  const handleQuestionSubmit = (currentAnswerData, finished = false) => {
    // delete currentAnswerData.reportReason;
    // resultId,isFlagged,longAnswer
    const longAnswer = {
      Observations: currentAnswerData.Observations,
      Interpretation: currentAnswerData.Interpretation,
      Diagnosis: currentAnswerData.Diagnosis,
      Differential: currentAnswerData.Differential,
      Management: currentAnswerData.Management,
    };
    let data = {
      longAnswer,
      isFlagged: currentAnswerData.isFlagged,
      questionId: currentAnswerData.questionId,
      resultId,
      isExamFinished: currentAnswerData.isExamFinished,
      reportReason: currentAnswerData.reportReason,
    };

    resultService.upsertLongAnswer(data).then((res) => {
      setLoading(false)
      if (finished) {
        setScoreBoardVisible(true);
      }
    });
  };
  const handleDrawerItemClick = (questionId, index) => {
    questionService.getOneQuestion(questionId).then((res) => {
      if (res.status) {
        setQuestionsData(res.data);
      }
    });
    const prevQuestionId = questions?.[0]?.questions?.[currentQuestion];
    const nextValue = index;
    const nextQuestionId = questions?.[0]?.questions?.[nextValue];
    questionChangeHelper(prevQuestionId, nextQuestionId);
    setCurrentQuestion(index);
  };

  const questionChangeHelper = (prevQuestionId, changeQuestionId) => {
    let answerArray = answers;
    currentAnswer.questionId = prevQuestionId;
    //api function for save  the user's answer to database
    if (
      currentAnswer.Observations ||
      currentAnswer.Diagnosis ||
      currentAnswer.Management ||
      currentAnswer.Differential ||
      currentAnswer.Interpretation
    ) {
      handleQuestionSubmit(currentAnswer);
    }
    //prev data upsert
    let prevExitIndex = answerArray?.findIndex(
      (data) => data?.questionId === prevQuestionId
    );
    if (prevExitIndex !== -1) {
      answerArray[prevExitIndex] = currentAnswer;
    } else {
      answerArray.push(currentAnswer);
    }
    setAnswers(answerArray);
    //change data  initialized
    let changeExitIndex = answerArray?.findIndex(
      (data) => data?.questionId === changeQuestionId
    );
    if (changeExitIndex !== -1) {
      let exitAnswerArray = answerArray[changeExitIndex];
      if (!exitAnswerArray.radAnswer) exitAnswerArray.radAnswer = "";
      if (!exitAnswerArray.radReason) exitAnswerArray.radReason = "";
      if (!exitAnswerArray.reportReason) exitAnswerArray.reportReason = "";
      setCurrentAnswer(exitAnswerArray);
    } else {
      setCurrentAnswer(() => ({
        Observations: "",
        Interpretation: "",
        Diagnosis: "",
        Differential: "",
        Management: "",
        reportReason: "",
        isFlagged: false,
        questionId: changeQuestionId,
      }));
    }
  };

  const handlePreviousQuestion = () => {
    const prevQuestionId = questions?.[0]?.questions?.[currentQuestion];
    const nextValue = Math.max(currentQuestion - 1, 0);
    const nextQuestionId = questions?.[0]?.questions?.[nextValue];
    questionChangeHelper(prevQuestionId, nextQuestionId);
    setCurrentQuestion((prevQuestion) => Math.max(prevQuestion - 1, 0));
  };

  const handleNextQuestion = () => {
    const prevQuestionId = questions?.[0]?.questions?.[currentQuestion];
    const nextValue = Math.min(
      currentQuestion + 1,
      questions?.[0]?.questions?.length - 1
    );
    const nextQuestionId = questions?.[0]?.questions?.[nextValue];
    questionChangeHelper(prevQuestionId, nextQuestionId);
    setCurrentQuestion((prevQuestion) =>
      Math.min(prevQuestion + 1, questions?.[0]?.questions?.length - 1)
    );
  };

  const handleFullScreenClick = () => {
    setIsFullScreen(true);
  };

  const handleCloseFullScreen = () => {
    setIsFullScreen(false);
  };

  const handleFlagSubmit = (questionId) => {
    resultService
      .flagQuestionToggle({ questionId, resultId })
      .then((res) => {});
  };

  const handleReportIconClick = () => {
    setReportModalOpen(true);
  };

  const handleReportModalClose = () => {
    setReportModalOpen(false);
  };
  const handleReportSubmit = () => {
    const data = {
      questionId: currentAnswer.questionId,
      reportReason: currentAnswer.reportReason,
      resultId,
    };
    resultService.reportQuestion(data).then((res) => {
      setShowReportReply(true);
    });
    // Close the report modal
    setReportModalOpen(false);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };
  const handleExitConfirmationYes = () => {
    navigate(-1); // Replace id with the appropriate value
  };

  const handleExitConfirmationNo = () => {
    // User canceled, close the modal
    setQuitModalOpen(false);
  };
  const handleReportReplyClose = () => {
    // Close the reply message box
    setShowReportReply(false);
  };

  useEffect(() => {
    if (timer === 0) {
      setTimeIsUp(true);
    }
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const filterStyles = {
    filter: `contrast(${contrast}) brightness(${brightness}%)`,
  };
  const handlePreviousImage = () => {
    if (imageIndex > 0) {
      setImageIndex((prevIndex) => prevIndex - 1);
    }
  };
  return (
    <div>
      {scoreBoardVisible || timeIsUp ? (
        <LongCaseScoreList
          subheadings={subheadings}
          resultId={resultId}
          examId={examId}
          showSolution={showSolution}
          currentQuestion={currentQuestion}
          handleViewSolution={handleViewSolution}
          handlePreviousQuestion={handlePreviousQuestion}
          handleNextQuestion={handleNextQuestion}
          handlePreviousImage={handlePreviousImage}
          handleNextImage={handleNextImage}
          imageIndex={imageIndex}
          setImageIndex={setImageIndex}
          examName={questions?.[0]?.examName}
        />
      ) : (
        <div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton sx={{ ml: 2 }} onClick={() => setQuitModalOpen(true)}>
              <FaArrowLeftLong style={{ color: "#EB568F" }} />
            </IconButton>
            <Modal
              open={quitModalOpen}
              onClose={() => setQuitModalOpen(false)}
              aria-labelledby="quit-modal"
              aria-describedby="quit-modal-description"
            >
              <Paper
                elevation={5}
                sx={{
                  p: 3,
                  width: 400,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                  Confirm Quit Mock
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Are you sure you want to quit the mock? Your progress will not
                  be saved.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleExitConfirmationYes}
                  sx={{ mr: 2 }}
                >
                  Yes
                </Button>
                <Button variant="contained" onClick={handleExitConfirmationNo}>
                  No
                </Button>
              </Paper>
            </Modal>
             <Typography sx={{ml:4, fontWeight:"bold"}}>{questions?.[0]?.examName}</Typography>
            <Button
              variant="outlined"
              sx={{
                borderRadius: "7px",
                margin: "0 auto",
                display: "block",
              }}
            >
              {timer > 0 ? (
                <Typography sx={{ textTransform: "none" }}>
                  Ends in{" "}
                  <span style={{ color: "red" }}>
                    {" "}
                    {Math.floor(timer / 60)}:{timer % 60 < 10 ? "0" : ""}
                    {timer % 60}
                  </span>
                </Typography>
              ) : (
                <Typography
                  variant="h6"
                  sx={{ color: "red", textAlign: "center" }}
                >
                  Time's Up!
                </Typography>
              )}
            </Button>

            <Button
              variant="contained"
              onClick={handleEndExam}
              disabled={loading}
              sx={{
                mr: 6,
                width: "8%",
                background: "#EB568F",
                "&:hover": {
                  backgroundColor: "#8890A7",
                },
              }}
            >
              End Exam
            </Button>
          </Box>
          <div>
            <Paper
              elevation={0}
              sx={{
                padding: "20px",

                width: "98%",
                display: "flex", // Add this line
                flexWrap: "wrap", // Add this line
              }}
            >
              {questions?.[0]?.questions?.map((id, index) => (
                <Paper
                  key={index}
                  sx={{
                    p: 1,
                    backgroundColor:
                      currentQuestion === index ? "#EB568F" : "inherit",
                    color: currentQuestion === index ? "white" : "inherit",
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                    width: "3.2%", // Adjust the width to fit 25 numbers in one row
                    boxSizing: "border-box",
                    textAlign: "center",
                    margin: "0 10px 10px 0",
                  }}
                  onClick={() => handleDrawerItemClick(id, index)}
                >
                  {index + 1}
                </Paper>
              ))}
            </Paper>
          </div>
          <div>
            <Paper elevation={0} sx={{ padding: "20px", width: "98%" }}>
              <Card elevation={20} sx={{ display: "flex" }}>
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "30px",
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Question {currentQuestion + 1}:
                    </Typography>

                    <div>
                      {/* Flag Icon */}
                      <Button
                        variant="text"
                        onClick={() => {
                          setCurrentAnswer((prev) => ({
                            ...prev,
                            isFlagged: !prev.isFlagged,
                          }));
                          handleFlagSubmit(questionData._id);
                        }}
                        sx={{
                          color: currentAnswer.isFlagged
                            ? "#EB568F"
                            : "inherit",
                        }}
                      >
                        <FaFlag style={{ width: "25px", height: "25px" }} />
                        <span style={{ fontSize: "14px", marginLeft: "10px" }}>
                          Flag
                        </span>
                      </Button>
                      {/* Report Icon */}
                      <Button
                        variant="text"
                        sx={{ color: "black" }}
                        onClick={handleReportIconClick}
                      >
                        {/* Replace the following icon with the desired "Report" icon */}
                        <GoReport style={{ width: "25px", height: "25px" }} />
                        <span style={{ fontSize: "14px", marginLeft: "10px" }}>
                          Report
                        </span>
                      </Button>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                        marginLeft: "30px",
                      }}
                    >
                      {/* Report Modal */}
                      <Modal
                        open={reportModalOpen}
                        onClose={handleReportModalClose}
                        aria-labelledby="report-modal"
                        aria-describedby="report-modal-description"
                      >
                        <Paper
                          elevation={5}
                          sx={{
                            p: 3,
                            width: 400,
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <Typography variant="h5" gutterBottom sx={{ mb: 2 }}>
                            Report this Question ?
                          </Typography>
                          <TextField
                            label="Reason"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth
                            value={currentAnswer.reportReason}
                            onChange={(e) =>
                              setCurrentAnswer((prev) => ({
                                ...prev,
                                reportReason: e.target.value,
                              }))
                            }
                            sx={{ mb: 2 }}
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleReportSubmit}
                          >
                            Submit
                          </Button>
                        </Paper>
                      </Modal>
                      <Snackbar
                        sx={{ ml: 30 }}
                        open={showReportReply}
                        autoHideDuration={3000} // Adjust the duration as needed
                        onClose={handleReportReplyClose}
                        message="Updated your feedback successfully" // The message you want to display
                      />

                      <Button
                        onClick={handleMenuOpen}
                        sx={{
                          position: "relative",
                          left: "10rem",
                          color: buttonColor,
                        }}
                      >
                        Image Settings
                      </Button>
                      <Menu
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}
                        onClose={handleMenuClose}
                      >
                        <MenuItem>
                          <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                              }}
                            >
                              <div
                                style={{
                                  cursor: "pointer",
                                  color: "blue",
                                  marginBottom: "10px",
                                }}
                                onClick={handleFullScreenClick}
                              >
                                Fullscreen image
                              </div>
                              <div>
                                <Typography variant="body1">
                                  Contrast:
                                </Typography>
                                <Slider
                                  value={contrast}
                                  onChange={handleContrastChange}
                                  min={0.1}
                                  max={3}
                                  step={0.1}
                                  sx={{ width: "50px", marginBottom: "10px" }}
                                />
                              </div>
                              <div>
                                <Typography variant="body1">
                                  Brightness:
                                </Typography>
                                <Slider
                                  value={brightness}
                                  onChange={handleBrightnessChange}
                                  min={50}
                                  max={200}
                                  step={1}
                                  sx={{ width: "50px" }}
                                />
                              </div>
                            </div>
                          </Paper>
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                  <div>
                    <Typography variant="body1" sx={{ mb: -3 }}>
                      {questionData?.question}
                    </Typography>
                  </div>

                  {/* Render answer sections */}
                  {subheadings?.map((answer, index) => (
                    <div key={index}>
                      {answer !== "images" && ( // Exclude "images" subheading
                        <TextField
                          label={answer}
                          multiline
                          rows={3}
                          value={currentAnswer[answer]}
                          onChange={(e) =>
                            handleAnswerChange(answer, e.target.value)
                          }
                          fullWidth
                          variant="outlined"
                          InputLabelProps={{ style: { color: "black" } }}
                        />
                      )}
                    </div>
                  ))}

                  <div sx={{ mt: 2 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handlePreviousQuestion}
                      sx={{
                        width: "30%",
                        display: currentQuestion === 0 ? "none" : "block",
                        "&:hover": {
                          backgroundColor: "#EB568F",
                        },
                      }}
                    >
                      Previous
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNextQuestion}
                      sx={{
                        width: "30%",
                        mt: 2,
                        display:
                          currentQuestion ===
                          questions?.[0]?.questions?.length - 1
                            ? "none"
                            : "block",
                        "&:hover": {
                          backgroundColor: "#EB568F",
                        },
                      }}
                    >
                      Next
                    </Button>

                    {currentQuestion ===
                      questions?.[0]?.questions?.length - 1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleEndExam}
                        disabled={loading}
                        sx={{
                          width: "30%",
                          mt: 2,
                          "&:hover": {
                            backgroundColor: "#EB568F",
                          },
                        }}
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </CardContent>

                <Box
                  sx={{
                    width: "40%",
                    position: "relative",
                    overflow: "hidden",
                    ml: 20,
                    mt: 13,
                    height: "50%",
                  }}
                >
                  {/* ReactPanZoom for image display */}
                  <ReactPanZoom
                    image={`${ImageBaseUrl}${questionData?.image?.[imageIndex]}`}
                    alt={`Question ${currentQuestion + 1}`}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      position: "absolute",
                      top: "5px",
                      left: "5px",
                    }}
                  >
                    Image {imageIndex + 1}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      color: "white",
                      position: "absolute",
                      top: "5px",
                      left: "150px",
                      cursor: "pointer",
                    }}
                    onClick={handleFullScreenClick}
                  >
                    Full Screen
                  </Typography>
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    {imageIndex > 0 && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                          setImageIndex((prevIndex) => prevIndex - 1)
                        }
                      >
                        Previous Image
                      </Button>
                    )}

                    {imageIndex < questionData?.image?.length - 1 && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleNextImage}
                        sx={{ ml: 2 }}
                      >
                        Next Image
                      </Button>
                    )}
                  </Box>
                </Box>
              </Card>
            </Paper>
          </div>

          <Modal
            open={isFullScreen}
            onClose={handleCloseFullScreen}
            aria-labelledby="fullscreen-modal"
            aria-describedby="fullscreen-modal-description"
          >
            <div>
              <Menu
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={handleMenuClose}
                sx={{ ml: 50 }}
              >
                <MenuItem>
                  <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <div
                        style={{
                          cursor: "pointer",
                          color: "blue",
                          marginBottom: "10px",
                        }}
                        onClick={handleFullScreenClick}
                      >
                        Fullscreen image
                      </div>
                      <div>
                        <Typography variant="body1">Contrast:</Typography>
                        <Slider
                          value={contrast}
                          onChange={handleContrastChange}
                          min={0.1}
                          max={3}
                          step={0.1}
                          sx={{ width: "50px", marginBottom: "10px" }}
                        />
                      </div>
                      <div>
                        <Typography variant="body1">Brightness:</Typography>
                        <Slider
                          value={brightness}
                          onChange={handleBrightnessChange}
                          min={50}
                          max={200}
                          step={1}
                          sx={{ width: "50px" }}
                        />
                      </div>
                    </div>
                  </Paper>
                </MenuItem>
              </Menu>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "30%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ReactPanZoom
                  image={`${ImageBaseUrl}${questionData?.image?.[imageIndex]}`}
                  alt={`Question ${selectedImageIndex + 1}`}
                  className="pan-zoom-container"
                  style={{
                    ...filterStyles,
                    maxWidth: "100%",
                    maxHeight: "80vh",
                  }}
                />

                <IconButton
                  onClick={handleCloseFullScreen}
                  sx={{
                    position: "relative",
                    bottom: "36rem",
                    left: "20rem",
                    zIndex: 9999,
                    alignSelf: "flex-end",
                    bgcolor: "#EB568F",
                  }}
                >
                  <RiCloseFill style={{ color: "white" }} />
                </IconButton>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default LongCase;

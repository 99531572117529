import React, { useState, useEffect, useContext } from "react";
import { examService, resultService } from "../../service/index";
import {
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { IoIosFolder } from "react-icons/io";

import { useNavigate, useParams } from "react-router-dom";
import Flagged from "../Flagged/FlaggedQuestions";
import Reported from "../reported questions/ReportedQuestions";

import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";

import Swal from "sweetalert2";

import FolderOpen from "./FolderOpen";
import { loaderContext } from "../../context";
import DrawerComponent from "./Drawer";

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}
const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 20,
}));
const size = {
  width: 220,
  height: 110,
};

const Dashboard = ({ defaultDrawerCourse }) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { drawerActiveLink } = useContext(loaderContext);

  const [openFolder, setOpenFolder] = useState(null);

  const navigate = useNavigate();

  const otherCardsData = [
    { value: 20, color: "#EB568F" },
    { value: 80, color: "#ECD1DB" },
  ];

  const [openCoursesData, setOpenCourseData] = useState([]);
  const [openFoldersData, setOpenFolderData] = useState([]);

  const handleFolderClick = (contentId) => {
    navigate(`/folder/${id}/${contentId}`);
  };

  const handleBackClick = () => {
    setOpenFolder(null);
    setOpenFolderData([]);
  };

  const handleContinueClick = (examId, questionType, questions) => {
    if (!questions?.length) {
      return Swal.fire("Questions not available", "", "warning");
    }
    Swal.fire({
      title: "Are you ready to start the exam?",
      // text: "Once started, you cannot go back. Make sure you are prepared.",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, start the exam",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        resultService.createResult(examId).then((res) => {
          if (questionType === "Rapid") {
            // Navigate to Rapid page
            navigate(`/rapid/${examId}/${res?.data?._id}`);
          } else if (questionType === "Longcase") {
            // Navigate to Longcase page
            navigate(`/longcase/${examId}/${res?.data?._id}`);
          } else {
            // Handle the case when the exam type is neither Rapid nor Longcase
            console.error(`Unknown exam type: ${questionType}`);
          }
          // console.log(res, "open course");
        });
      }
    });

    // if (questionType === "Rapid") {
    //     // Navigate to Rapid page
    //     navigate(`/rapid/${examId}`);
    //   } else if (questionType === "Longcase") {
    //     // Navigate to Longcase page
    //     navigate(`/longcase/${examId}`);
    //   } else {
    //     // Handle the case when the exam type is neither Rapid nor Longcase
    //     console.error(`Unknown exam type: ${questionType}`);
    //   }
  };

  useEffect(() => {
    setLoading(true);
    setOpenCourseData([]);
    window.scrollTo(0, 0);

    examService.viewExamBasedCourse(id).then((res) => {
      if (res.status) {
        setOpenCourseData(res.data);
      }
      setLoading(false);
    });
    setOpenFolder(null);
  }, [id]);

  // const mergedCoursesData = [...coursesData, ...newCourses];
  // const unlockedCourses = mergedCoursesData.filter((course) => !course.locked);

  const cardColors = [
    "linear-gradient(to right, #f5f9c5, #f9f3de, #fbefed)",
    "linear-gradient(to right, #e2ecfc, #f0eeec, #f9efe2)",
    "linear-gradient(to right, #fef3f6, #fff5e7, #fff5df)",
  ];

  return (
    <div>
      <DrawerComponent id={id} setOpenCourseData={setOpenCourseData} />

      <Box
        sx={{
          width: "100%",
          height: "100vh",
          marginLeft: "200px",
          paddingLeft: 10,
          mt: 5,
        }}
      >
        {drawerActiveLink === "course" && (
          <>
            {openCoursesData?.length ? (
              <div>
                <>
                  {!openFolder &&
                  openCoursesData?.filter((data) => data.isFolder === true)
                    ?.length ? (
                    <Typography variant="h6" sx={{ m: 1, ml: 2 }}>
                      Our contents
                    </Typography>
                  ) : (
                    ""
                  )}
                  {/* mergedCourseData */}
                  {!openFolder &&
                    openCoursesData
                      ?.filter((data) => data.isFolder === true)
                      ?.map((folder, index) => (
                        <Paper
                          key={index}
                          elevation={3}
                          sx={{
                            padding: "20px",
                            marginBottom: "20px",
                            width: "200px",
                            display: "inline-block",
                            margin: "10px",
                            borderRadius: "20px",
                            backgroundColor: "#E3F2FD",
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: "white",
                            },
                          }}
                          onClick={() => handleFolderClick(folder?.contentId)}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <IoIosFolder
                              size={30}
                              style={{
                                marginRight: "10px",
                                color: "#1976D2",
                              }}
                            />
                            <Typography
                              sx={{
                                fontSize: "16px",
                                fontFamily: "Poppins",
                              }}
                            >
                              {folder?.contentName}
                            </Typography>
                          </Box>
                        </Paper>
                      ))}
                  {!openFoldersData.length && !openFolder ? (
                    <>
                      {openCoursesData?.filter(
                        (data) => data.isFolder === false
                      )?.length ? (
                        <Typography variant="h6" sx={{ m: 2 }}>
                          Exams
                        </Typography>
                      ) : (
                        ""
                      )}
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {openCoursesData
                          ?.filter((data) => data.isFolder === false)
                          ?.map((rapid, index) => (
                            <Card
                              key={index}
                              onClick={() =>
                                handleContinueClick(
                                  rapid.contentId,
                                  rapid.questionType,
                                  rapid.questions
                                )
                              }
                              sx={{
                                margin: "10px",
                                width: "15%",
                                borderRadius: "20px",
                                background:
                                  cardColors[index % cardColors.length],
                                border: "white",
                                cursor: "pointer",
                                transition: "width 0.3s, height 0.3s",
                                "&:hover": {
                                  width: "20%",
                                  height: "120%",
                                },
                              }}
                            >
                              <CardContent
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Typography variant="h5" component="div">
                                  {rapid.contentName}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                  sx={{ color: "#333333" }}
                                >
                                  {rapid.questionType}
                                </Typography>
                                <Box sx={{ mt: 2, ml: 12 }}>
                                  <PieChart
                                    series={[
                                      { data: otherCardsData, innerRadius: 30 },
                                    ]}
                                    {...size}
                                  >
                                    <PieCenterLabel>
                                      {`${otherCardsData?.[0].value}%`}
                                    </PieCenterLabel>
                                  </PieChart>
                                </Box>
                              </CardContent>
                            </Card>
                          ))}
                      </div>
                    </>
                  ) : (
                    <>
                      <FolderOpen
                        openFoldersData={openFoldersData}
                        handleContinueClick={handleContinueClick}
                        handleBackClick={handleBackClick}
                      />
                    </>
                  )}
                </>
              </div>
            ) : loading ? (
              <CircularProgress />
            ) : (
              <Typography>No Record Found</Typography>
            )}
          </>
        )}
        {drawerActiveLink === "report" && <Reported />}
        {drawerActiveLink === "flag" && <Flagged />}
      </Box>
    </div>
  );
};
export default Dashboard;

import { Box } from "@mui/material";
import React from "react";
 
// import Drawer from "./Drawer";
// import Loader from "../Loader";

const Public = ({ children }) => {
  // const [loading, setLoading] = useState(true);

  // if (loading) {
  //     return <Loader />;
  // }
  return (
    <Box className="PPP">
      <Box className="">{children}</Box>
    </Box>
  );
};

export default Public;

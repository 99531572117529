import * as React from "react";
import "./drawer.css";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import NavBarTwo from "../navbar/NavBarTwo";
import { getAuthStorage } from "../../utils/storage-utils";
import { useEffect } from "react";

const drawerWidth = 275;
function ResponsiveDrawer({ children }) {
  // const { id } = useParams();

  const [id, setUser] = React.useState(null);

  useEffect(() => {
    const session = getAuthStorage();
    setUser(session.userId);
  }, [id]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          zIndex: "9999",
        }}
      >
        <NavBarTwo />
      </AppBar>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "100vh",
          backgroundColor: "white",
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;

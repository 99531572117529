import { setAuthStorage } from "../utils/storage-utils";
import axios from "axios";
import { apiConfig } from "../config/api-config";
import { getAuthStorage } from "../utils/storage-utils";
import auth from "./auth-service";
import user from "./user-service";
import course from "./course-service";
import dashboard from "./dashboard-service";
import exam from "./exam-service";
import question from "./question-service";
import result from "./result-service";
import flag from "./flag-service";

// import activity from "./activity-service";

const httpClient = axios.create(apiConfig);

httpClient.interceptors.request.use(async (config) => {
  if (config.headers) {
    config.headers["x-domain-host"] = window.location.hostname || "";
  }

  const authData = getAuthStorage();
  if (authData && config.headers) {
    config.headers.Authorization = `Bearer ${authData.accessToken}`;
  }

  if (!config.params) {
    config.params = {};
  }

  config.params["cache-invalidate"] = Date.now();
  return config;
});

// const logout = () => {
//   clearStorage();
//   clearSessionStorage();
//   window.location.href = `/`;
// };

httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.message === "Network Error") {
      return Promise.resolve(); // Return a resolved Promise to suppress the error.
    }

    if (error.request.responseURL.includes("/api/sign-in")) {
      return Promise.resolve(); // Return a resolved Promise to suppress the error.
    }

    if (
      error.response.status === (401 || 403) &&
      error.config &&
      !error.config.__isRetryRequest
    ) {
      originalRequest._retry = true;
      try {
        const authData = getAuthStorage();
        const refreshToken = authData.refreshToken;
        const response = await httpClient.post("/refresh-token", {
          refreshToken,
        });
        if (response.data.accessToken) {
          const { accessToken } = response.data;
          setAuthStorage({ ...authData, accessToken });
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
        }
        // Retry the original request with the new token
        return axios(originalRequest);
      } catch (error) {
        // logout();
        // return Promise.resolve();
      }
    }

    // if (
    //   error.response.status === 403 &&
    //   error.config &&
    //   !error.config.__isRetryRequest
    // ) {
    //   logout();
    //   return Promise.resolve(); // Return a resolved Promise to suppress the error.
    // }

    return Promise.reject(error); // Re-throw the error if none of the conditions match.
  }
);

const authService = auth(httpClient);

const userService = user(httpClient);

const courseService = course(httpClient);

const dashboardService = dashboard(httpClient);
const examService = exam(httpClient);
const questionService = question(httpClient);
const resultService = result(httpClient);
const flagService = flag(httpClient);

export {
  authService,
  userService,
  courseService,
  dashboardService,
  examService,
  questionService,
  resultService,
  flagService,
};

const questionService = (httpClient) => {
    //open folder
    const getOneQuestion = async (questionId) => {
      try {
        const res = await httpClient.post("/question/get-question",{questionId});
        return res.data;
      } catch (error) {
        return { status: false, message: "Failed to fetch users" };
      }
    };
    return {
        getOneQuestion, 
    };
  };
  
  export default questionService;